import React, {useEffect, useState} from 'react';
import Template from "../../layouts/Template";
import './index.scss';
import imageLogo from "../../assets/images/cartracker-logo-1.png";
import DialogAlert from "../../components/DialogAlert";
import {useNavigate} from "react-router-dom";
import {Link, Checkbox, FormControlLabel, TextField, DialogActions, Button} from "@mui/material";
import {LoadingButton} from "@mui/lab";
import {SubmitHandler, useForm} from "react-hook-form";
import restApi from "../../api/restApi";
import {userState} from "../../states/userState";
import {useAppDispatch} from "../../states/hooks";
import IndexedDb from "../../database/indexedDb";
import {tableNames} from "../../database/interfaceDb";
import Dialog from "@mui/material/Dialog/Dialog";
import DialogContent from "@mui/material/DialogContent/DialogContent";

//**********************************************************

let indexedDb: IndexedDb;
let tryLogin: number = 0;

interface FormLoginInput
{
    email: string,
    password: string,
    remember: boolean,
    isTracking: boolean
}

//**********************************************************

function Index()
{
    // let indexedDb: IndexedDb;
    const navigate = useNavigate();
    const dispatch = useAppDispatch()
    const [isSaving, setIsSaving] = useState<boolean>(false);
    const [formLoginEmail, setFormLoginEmail] = useState<string>('');
    const [formLoginPassword, setFormLoginPassword] = useState<string>('');
    const [formLoginRemember, setFormLoginRemember] = useState<boolean>(false);
    const [formLoginTracking, setFormLoginTracking] = useState<boolean>(false);

    const dialogAlert = React.createRef<any>();
    const [dialogAlertType, setDialogAlertType] = useState<string>('');
    const [dialogAlertTitle, setDialogAlertTitle] = useState<string>('');
    const [dialogAlertDes, setDialogAlertDes] = useState<string>('');

    const [dialogRenewalIsOpen, setDialogRenewalIsOpen] = useState<boolean>(false);

    // open db
    const initDB = () =>
    {
        indexedDb = new IndexedDb();
        indexedDb.open().then(r => { checkTableUser(); });
    };
    useEffect(() => { initDB(); }, []);

    // form
    const { register, formState: { errors }, handleSubmit , setValue} = useForm<FormLoginInput>({defaultValues: { email: '', password: '', remember: false }})
    const onSubmit: SubmitHandler<FormLoginInput> = (formData:FormLoginInput) =>
    {
        formSubmit(formData)

        // const da = dialogAlert.current;
        // sessionStorage.setItem(String(process.env.REACT_APP_Offline), "0");
        // setIsSaving(true);
        //
        // restApi.post('/session', formData).then
        // (
        //     async function(response)
        //     {
        //         if(response.id !== undefined && response.id > 0)
        //         {
        //             formData.isTracking = formLoginTracking;
        //
        //             // save user data in session
        //             dispatch(userState.actions.save(formData));
        //
        //             // save user data in db
        //             if(formData.remember && !formLoginTracking)
        //             {
        //                 await indexedDb.putValue(tableNames.Users, {email: formData.email, password: formData.password, data: JSON.stringify(response)});
        //             }
        //
        //             // renewal
        //             var d1 = new Date();
        //             var d2 = new Date(response.expirationTime);
        //             var renewalDays = (d2.getTime() - d1.getTime()) / (1000 * 60 * 60 * 24);
        //
        //             if(renewalDays <= 14) sessionStorage.setItem(String(process.env.REACT_APP_Renewal), "1");
        //
        //             tryLogin = 100;
        //
        //             // redirect to home or tracking
        //             navigate(formLoginTracking ? "/tracking" : "/home");
        //         }
        //         else
        //         {
        //             /*
        //              if(!helpers.inOnline() && !formLoginTracking)
        //              {
        //              offlineLogin();
        //              }
        //              else
        //              {
        //              setDialogAlertTitle("");
        //              setDialogAlertDes("اطلاعات وارد شده اشتباه می باشد.");
        //              setDialogAlertType("error");
        //              da.show();
        //              }
        //              */
        //
        //             if(response?.response?.data === "NullPointerException")
        //             {
        //                 setDialogAlertTitle("");
        //                 setDialogAlertDes("اطلاعات وارد شده اشتباه می باشد.");
        //                 setDialogAlertType("error");
        //                 da.show();
        //
        //                 tryLogin = 100;
        //             }
        //             else if(response?.response?.data.includes("expired"))
        //             {
        //                 setDialogRenewalIsOpen(true);
        //
        //                 tryLogin = 100;
        //             }
        //                 // else if(tryLogin >= 4 && !formLoginTracking)
        //                 // {
        //                 //     setTimeout(offlineLogin, 1000);
        //             // }
        //             else
        //             {
        //                 if(tryLogin >= 2 && !formLoginTracking) offlineLogin();
        //                 // setDialogAlertTitle("");
        //                 // setDialogAlertDes("مشکلی در ورود پیش آمده است، مجدد تلاش کنید.");
        //                 // setDialogAlertDes("مشکلی در ورود پیش آمده است، مجدد تلاش کنید.");
        //                 // setDialogAlertType("error");
        //                 // da.show();
        //             }
        //         }
        //     }
        // ).catch
        // (
        //     function(error)
        //     {
        //         if(tryLogin >= 2 && !formLoginTracking) offlineLogin();
        //     }
        // ).finally
        // (
        //     function()
        //     {
        //         tryLogin += 1;
        //
        //         setIsSaving(false);
        //     }
        // );
    }

    const formSubmit = (formData:FormLoginInput) =>
    {
        const da = dialogAlert.current;
        sessionStorage.setItem(String(process.env.REACT_APP_Offline), "0");
        setIsSaving(true);

        restApi.post('/session', formData).then
        (
            async function(response)
            {
                if(response.id !== undefined && response.id > 0)
                {
                    formData.isTracking = formLoginTracking;

                    // save user data in session
                    dispatch(userState.actions.save(formData));

                    // save user data in db
                    if(formData.remember && !formLoginTracking)
                    {
                        await indexedDb.putValue(tableNames.Users, {email: formData.email, password: formData.password, data: JSON.stringify(response)});
                    }

                    // renewal
                    var d1 = new Date();
                    var d2 = new Date(response.expirationTime);
                    var renewalDays = (d2.getTime() - d1.getTime()) / (1000 * 60 * 60 * 24);

                    if(renewalDays <= 14) sessionStorage.setItem(String(process.env.REACT_APP_Renewal), "1");

                    // redirect to home or tracking
                    navigate(formLoginTracking ? "/tracking" : "/home");
                }
                else
                {
                    if(response?.response?.data === "NullPointerException")
                    {
                        setDialogAlertTitle("");
                        setDialogAlertDes("اطلاعات وارد شده اشتباه می باشد.");
                        setDialogAlertType("error");
                        da.show();

                        setIsSaving(false);
                    }
                    else if(response?.response?.data.includes("expired"))
                    {
                        setDialogRenewalIsOpen(true);

                        setIsSaving(false);
                    }
                    else if(!formLoginTracking)
                    {
                        tryLogin += 1;
                        if(tryLogin >= 3) offlineLogin();
                        else formSubmit(formData);
                    }
                    else
                    {
                        setDialogAlertTitle("");
                        setDialogAlertDes("مشکلی در ورود پیش آمده است، مجدد تلاش کنید.");
                        setDialogAlertType("error");
                        da.show();
                    }
                }
            }
        ).catch
        (
            function(error)
            {
                // if(tryLogin >= 2 && !formLoginTracking) offlineLogin();
            }
        ).finally
        (
            function()
            {
                // tryLogin += 1;
                // setIsSaving(false);
            }
        );
    }

    //
    const offlineLogin = () =>
    {
        sessionStorage.setItem(String(process.env.REACT_APP_Offline), "1");

        // save user data in session
        dispatch(userState.actions.save({email: "offline", password: "offline"}));

        // redirect to home
        navigate("/home");
    }

    // check user data from db
    const checkTableUser = async () =>
    {
        const userData = await indexedDb.getAllValue(tableNames.Users);
        if (userData.length > 0)
        {
            setFormLoginEmail(userData[0].email);
            setFormLoginPassword(userData[0].password);
            setFormLoginRemember(true);

            setValue('email', userData[0].email);
            setValue('password', userData[0].password);
            setValue('remember', true);

            // save user data in session
            // dispatch(userState.actions.save(userData[0]));
            // redirect to home
            // navigate("/home");
        }
    };

    // renewal
    const handleDialogRenewalClose = () =>
    {
        setDialogRenewalIsOpen(false);
    };

    //--------------------
    return (
        <Template pageClass="page-login" showHeader={false} showFooter={false}>
            <img className="logo" src={imageLogo} alt=""/>
            <div className="title">نرم افزار ردیابی خودرو ایران جی پی اس</div>

            {/* --- form --- */}
            <form className="form" onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off">
                <TextField label="نام کاربری/شماره همراه" id="formLoginEmail" variant="filled" autoComplete="new-email" dir="ltr" {...register("email", {required: true})} error={errors.email?.type === "required"} value={formLoginEmail} onChange={(e) => setFormLoginEmail(e.target.value)} />
                <TextField type="password" label="کلمه عبور" id="formLoginPassword" variant="filled" autoComplete="new-password" dir="ltr" {...register("password", {required: true})} error={errors.password?.type === "required"} value={formLoginPassword} onChange={(e) => setFormLoginPassword(e.target.value)} />
                <FormControlLabel control={<Checkbox {...register("remember")} checked={formLoginRemember} onClick={() => setFormLoginRemember(!formLoginRemember)} />} label="کلمه عبور را به یاد داشته باش!"/>
                <div className="btn-box">
                    <LoadingButton type="submit" fullWidth={true} size="large" variant="contained" color="success" loading={isSaving} disabled={isSaving}>ورود به سامانه پیامک</LoadingButton>
                    <LoadingButton type="submit" fullWidth={true} size="large" variant="contained" color="info" loading={isSaving} disabled={isSaving} onClick={() => setFormLoginTracking(true)}>ورود به سامانه ردیابی</LoadingButton>
                </div>
            </form>

            {/* --- links --- */}
            <div className="links">
                {/*<Link href="#" underline="none">سامانه ردیابی جدید</Link>*/}
                <Link href="https://radyabi24.ir/" underline="none">سامانه ردیابی تحت وب</Link>
            </div>

            {/* modal renewal */}
            <Dialog fullWidth={true} open={dialogRenewalIsOpen} onClose={handleDialogRenewalClose}>
                <DialogContent>اعتبار حساب کاربری شما تمام شده است. برای تمدید هم اکنون اقدام نمایید.</DialogContent>

                <DialogActions>
                    <Button size="medium" variant="outlined" onClick={handleDialogRenewalClose}>لغو</Button>
                    <Button size="medium" variant="contained" color="secondary" component={Link} href="https://Irangps.net" target="_blank">تمدید اکانت</Button>
                </DialogActions>
            </Dialog>

            {/* --- --- --- */}
            <DialogAlert ref={dialogAlert} title={dialogAlertTitle} des={dialogAlertDes} type={dialogAlertType} isClose={true} />
        </Template>
    );
}

export default Index;
