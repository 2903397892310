import React from "react";
import "./index.scss";

//**********************************************************
function Index()
{
    //--------------------
    return(
        <div className="loading-over">
            <div className="loading-box">
                <div className="c1"></div>
                <div className="c2"></div>
                <div></div>
            </div>
        </div>
    )
}

export default Index;
