import React, {useState} from 'react';
import Template from "../../layouts/Template";
import {TextField} from "@mui/material";
import {useAppSelector} from "../../states/hooks";
import CurrentDevice from "../../components/CurrentDevice";
import {LoadingButton} from "@mui/lab";
import DialogAlert from "../../components/DialogAlert";
import {SubmitHandler, useForm} from "react-hook-form";
import smsDevice from "../../utils/smsDevice";
import "./pointDistance.scss";
import helpers from "../../utils/helpers";

//**********************************************************

interface FormDeviceInput
{
    distanceMinute: number;
    distanceSecond: number;
}

//**********************************************************

function PointDistance()
{
    const currentDeviceState = useAppSelector(state => state.deviceState);

    //
    const dialogAlert = React.createRef<any>();
    const [dialogAlertType, setDialogAlertType] = useState<string>('');
    const [dialogAlertTitle, setDialogAlertTitle] = useState<string>('');
    const [dialogAlertDes, setDialogAlertDes] = useState<string>('');

    // form
    const { register, formState: { errors }, handleSubmit } = useForm<FormDeviceInput>()
    const onSubmit: SubmitHandler<FormDeviceInput> = async (formData:FormDeviceInput) =>
    {
        if(formData.distanceMinute > 0 && formData.distanceSecond > 0)
        {
            // send sms
            // window.open("sms:" + currentDeviceState.mobile + "?&body=" + smsDevice.Coban.pointDistance.code.replace('Second', formData.distanceSecond.toString()).replace('Minute', formData.distanceMinute.toString()));
            helpers.sendSms(currentDeviceState.mobile, smsDevice.Coban.pointDistance.code.replace('Second', formData.distanceSecond.toString()).replace('Minute', formData.distanceMinute.toString()));
        }
        else
        {
            setDialogAlertTitle("");
            setDialogAlertDes("لطفا مقادیر هر دو فیلد را بصورت عدد و بزرگتر از صفر وارد نمایید.");
            setDialogAlertType("error");
            dialogAlert.current.show();
        }
    }

    //--------------------
    return (
        <Template pageClass="page-point-distance" pageTitle="تغییر شماره سیم کارت دستگاه">
            <CurrentDevice />

            {/* --- form --- */}
            <form className="form" onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off">
                <TextField type="number" label="موتور خاموش بر حسب دقیقه" id="formPointDistanceMinute" variant="filled" autoComplete="new-minute" dir="ltr" {...register("distanceMinute", {required: true})} error={errors.distanceMinute?.type === "required"} />
                <TextField type="number" label="موتور روشن بر حسب ثانیه" id="formPointDistanceSecond" variant="filled" autoComplete="new-second" dir="ltr" {...register("distanceSecond", {required: true})} error={errors.distanceSecond?.type === "required"} />

                <LoadingButton type="submit" fullWidth={true} size="large" variant="contained" color="success">ثبت</LoadingButton>
            </form>

            {/* --- --- --- */}
            <DialogAlert ref={dialogAlert} title={dialogAlertTitle} des={dialogAlertDes} type={dialogAlertType} isClose={true} />
        </Template>
    );
}

export default PointDistance;
