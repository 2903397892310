import React from 'react';
import {Navigate, Outlet} from 'react-router-dom';
import {useAppSelector} from "../states/hooks";

//**********************************************************

function RouterPublic()
{
    const userData = useAppSelector(state => state.userState);

    return <>{ (userData.email === "" || userData.password === "") ? <Outlet /> : (userData.isTracking ? <Navigate to="/tracking" /> : <Navigate to="/home" />) }</>;
}

export default RouterPublic;
