import React, {useEffect, useState} from 'react';
import './index.scss';
import {useAppSelector} from "../../states/hooks";
import {useNavigate} from "react-router-dom";
import {MapContainer, Marker, Polyline, Popup, TileLayer, useMap, Tooltip} from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L, {divIcon, LineCapShape} from 'leaflet';
import restApi from "../../api/restApi";
import KeyboardBackspaceTwoToneIcon from "@mui/icons-material/KeyboardBackspaceTwoTone";
import ModeOfTravelTwoToneIcon from '@mui/icons-material/ModeOfTravelTwoTone';
import LayersTwoToneIcon from '@mui/icons-material/LayersTwoTone';
import {Button, Checkbox, DialogActions, FormControlLabel, Menu, MenuItem} from "@mui/material";
import {LatLngExpression} from "leaflet";
import helpers from "../../utils/helpers";
import LocationSearchingIcon from '@mui/icons-material/LocationSearching';
import PauseCircleIcon from '@mui/icons-material/PauseCircle';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import imageIconCar from '../../assets/images/icon-car.png';
import imageIconPoint from '../../assets/images/icon-point.png';
import LoadingOver from "../../components/LoadingOver";
import Dialog from "@mui/material/Dialog/Dialog";
import DialogContent from "@mui/material/DialogContent/DialogContent";
import { MobileTimePicker } from '@mui/x-date-pickers/MobileTimePicker';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { Slider } from '@mui/material';
import {renderToStaticMarkup} from "react-dom/server";
import NavigationTwoToneIcon from '@mui/icons-material/NavigationTwoTone';
import RestoreTwoToneIcon from '@mui/icons-material/RestoreTwoTone';

//**********************************************************

let currentPositions: any = [];
let currentPositionTimer: any = 0;
let routePositionTimer: any = 0;
let routePositionIndex: number = 0;
let iconDirectionSign: any = [];

enum mapLayers
{
    // map1 = 'http://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}',
    // map1 = 'https://core-sat.maps.yandex.net/tiles?l=sat&x={x}&y={y}&z={z}',
    map1 = 'https://mt1.google.com/vt/lyrs=y&x={x}&y={y}&z={z}',
    // map2 = 'https://mt1.google.com/vt/lyrs=s&x={x}&y={y}&z={z}',
    // map2 = 'https://mt1.google.com/vt/lyrs=m&x={x}&y={y}&z={z}',
    // map2 = 'https://b.tile-cyclosm.openstreetmap.fr/cyclosm/{z}/{x}/{y}.png',
    map2 = 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
}

//**********************************************************

function Index(props: any)
{
    const navigate = useNavigate();
    const userState = useAppSelector(state => state.userState);
    const [devices, setDevices] = useState<any>(null);
    const [currentDevice, setCurrentDevice] = useState<any>(null);
    const [routePositions, setRoutePositions] = useState<any>([]);
    const [routePositionsPlay, setRoutePositionsPlay] = useState<any>([]);
    const [currentPosition, setCurrentPosition] = useState<any>([]);
    const [mapCenterPosition, setMapCenterPosition] = useState<LatLngExpression>({lat: 35.7129854, lng: 51.3607368});
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const openDeviceItems = Boolean(anchorEl);
    const [anchorElFilter, setAnchorElFilter] = React.useState<null | HTMLElement>(null);
    const openFilter = Boolean(anchorElFilter);
    const [anchorElLayer, setAnchorElLayer] = React.useState<null | HTMLElement>(null);
    const openLayer = Boolean(anchorElLayer);
    const [showStopPoint, setShowStopPoint] = useState<boolean>(true);
    const [showDirectionSign, setShowDirectionSign] = useState<boolean>(false);
    const [isPlay, setIsPlay] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const [filterDate1, setFilterDate1] = useState("");
    const [filterDate2, setFilterDate2] = useState("");
    const [filterTime1, setFilterTime1] = useState("");
    const [filterTime2, setFilterTime2] = useState("");
    const [dialogFilterDateIsOpen, setDialogFilterDateIsOpen] = useState(false);

    const [openPopup, setOpenPopup] = useState(true);
    const [mapLayer, setMapLayer] = useState(mapLayers.map2);
    const [routePositionsPlayIndex, setRoutePositionsPlayIndex] = useState(0);

    // icons
    const iconCar = L.icon({iconUrl: imageIconCar, className: "icon-car"});
    const iconPoint = L.icon({iconUrl: imageIconPoint, className: "icon-point", iconSize: new L.Point(1, 1)});

    //
    useEffect
    (
        () =>
        {
            // const iconMarkup = renderToStaticMarkup(<img src={imageIconCar} alt="" className="icon-car" />);
            const iconMarkup = renderToStaticMarkup(<NavigationTwoToneIcon className="icon-car" />);
            for(let i = 0; i <= 360; i++)
            {
                iconDirectionSign[i] = L.divIcon({html: iconMarkup, className: "icon-direction-sign t" + i});
                // iconDirectionSign[i] = L.icon({iconUrl: imageIconCar, className: "icon-direction-sign t" + i, iconSize: new L.Point(16, 16)});
            }

            getDevices();
        },
        []
    );

    // get devices from server
    const getDevices = () =>
    {
        setIsLoading(true);

        //
        restApi.get('/devices', null, userState).then
        (
            async function(response)
            {
                if(response != null && response.length > 0)
                {
                    setDevices(response);
                    setCurrentDevice(response[response.length - 1]);
                }
                else
                {
                    // show error
                }
            }
        ).catch
        (
            function(error)
            {
            }
        ).finally
        (
            function()
            {
                setIsLoading(false);
            }
        );
    };

    // get current position
    const getCurrentPosition = (deviceId: number, showLoading: boolean = true) =>
    {
        setIsLoading(showLoading);

        let dateToday1 = new Date();
        let dateToday2 = new Date();
        dateToday1.setMinutes(dateToday1.getMinutes() - 60);
        dateToday2.setMinutes(dateToday2.getMinutes() - dateToday2.getTimezoneOffset());
        dateToday2.setUTCHours(23,59,59,59);

        //
        restApi.get('/positions', {deviceId: deviceId}, userState).then
        // restApi.get('/positions', {deviceId: deviceId, from: dateToday1.toISOString().slice(0, -5) + 'Z', to: dateToday2.toISOString().slice(0, -5) + 'Z'}, userState).then
        (
            async function(response)
            {
                if(response != null && response.length > 0)
                {
                    const cpLen = 15;
                    /*
                    const cp = currentPosition;
                    cp[cp.length] = response[0];
                    let positions = (cp.length > cpLen) ? cp.slice(cp.length - cpLen, cp.length) : cp;
                    let position = positions[positions.length - 1];

                    setCurrentPosition(positions);
                    setMapCenterPosition({lat: position.latitude, lng: position.longitude});
                    */

                    if(currentPositions == null || currentPositions.length === 0 || currentPositions[currentPositions.length - 1].id !== response[0].id)
                    {
                        currentPositions[currentPositions.length] = response[0];

                        let positions = (currentPositions.length > cpLen) ? currentPositions.slice(currentPositions.length - cpLen, currentPositions.length) : currentPositions;
                        let position = positions[positions.length - 1];

                        setCurrentPosition(positions);
                        setMapCenterPosition({lat: position.latitude, lng: position.longitude});
                    }
                }
                else
                {
                    // show error
                }
            }
        ).catch
        (
            function(error)
            {
            }
        ).finally
        (
            function()
            {
                setIsLoading(false);
            }
        );
    };

    // get positions
    const getPositions = (datetime1:string, datetime2:string) =>
    {
        setIsLoading(true);

        //
        restApi.get('/positions', {deviceId: currentDevice.id, from: datetime1, to: datetime2}, userState).then
        (
            async function(response)
            {
                if(response != null && response.length > 0)
                {
                    let rp = [];

                    for (let i = 0; i < response.length; i++)
                    {
                        if(showStopPoint || response[i].speed > 0) rp[rp.length] = response[i];
                    }

                    setRoutePositions(rp);
                    setRoutePositionsPlay([]);
                    setMapCenterPosition({lat: rp[0].latitude, lng: rp[0].longitude});
                }
                else
                {
                    // show error
                }
            }
        ).catch
        (
            function(error)
            {
            }
        ).finally
        (
            function()
            {
                setIsLoading(false);
            }
        );
    };

    // handle device items
    const handleOpenDeviceItems = (event: React.MouseEvent<HTMLElement>) =>
    {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseDeviceItems = () =>
    {
        setAnchorEl(null);
    };

    const handleDeviceChange = (item:any) =>
    {
        handleCloseDeviceItems();
        setCurrentDevice(item);
        setCurrentPosition([]);
        currentPositions = [];

        if(currentPositionTimer > 0)
        {
            clearInterval(currentPositionTimer);
            currentPositionTimer = 0;
        }
    };

    // handle filter
    const handleOpenFilter = (event: React.MouseEvent<HTMLElement>) =>
    {
        setAnchorElFilter(event.currentTarget);
    };

    const handleCloseFilter = () =>
    {
        setAnchorElFilter(null);
    };

    const handleFilterChange = (index:number) =>
    {
        handleCloseFilter();

        //
        setCurrentPosition([]);
        setRoutePositions(null);
        setRoutePositionsPlay(null);
        currentPositions = [];

        if(currentPositionTimer > 0)
        {
            clearInterval(currentPositionTimer);
            currentPositionTimer = 0;
        }
        if(routePositionTimer > 0)
        {
            clearInterval(routePositionTimer);
            routePositionTimer = 0;
        }

        //
        switch (index)
        {
            case 1:
            {
                let dateToday1 = new Date();
                let dateToday2 = new Date();
                dateToday1.setMinutes(dateToday1.getMinutes() - dateToday1.getTimezoneOffset());
                dateToday1.setUTCHours(0,0,0,0);
                dateToday2.setMinutes(dateToday2.getMinutes() - dateToday2.getTimezoneOffset());
                dateToday2.setUTCHours(23,59,59,59);

                getPositions(dateToday1.toISOString().slice(0, -5) + 'Z', dateToday2.toISOString().slice(0, -5) + 'Z');

                break;
            }

            case 2:
            {
                let dateYesterday1 = new Date();
                let dateYesterday2 = new Date();
                dateYesterday1.setDate(dateYesterday1.getDate() - 1);
                dateYesterday1.setMinutes(dateYesterday1.getMinutes() - dateYesterday1.getTimezoneOffset());
                dateYesterday1.setUTCHours(0,0,0,0);
                dateYesterday2.setDate(dateYesterday2.getDate() - 1);
                dateYesterday2.setMinutes(dateYesterday2.getMinutes() - dateYesterday2.getTimezoneOffset());
                dateYesterday2.setUTCHours(23,59,59,59);

                getPositions(dateYesterday1.toISOString().slice(0, -5) + 'Z', dateYesterday2.toISOString().slice(0, -5) + 'Z');

                break;
            }

            case 3:
            {
                setDialogFilterDateIsOpen(true);

                break;
            }
        }
    };

    // handle layer
    const handleOpenLayer = (event: React.MouseEvent<HTMLElement>) =>
    {
        setAnchorElLayer(event.currentTarget);
    };

    const handleCloseLayer = () =>
    {
        setAnchorElLayer(null);
    };

    const handleLayerChange = (index:number) =>
    {
        handleCloseLayer();
        setMapLayer(index === 1 ? mapLayers.map1 : mapLayers.map2);
    };

    //
    const handlePlay = (currentIndex: number = 0) =>
    {
        let cp: any = [];
        let p = routePositions;
        let p2 = routePositionsPlay;
        routePositionIndex = currentIndex > 0 ? currentIndex : (p2 != null && p2.length > 0 ? p2.length : 0);

        setIsPlay(true);
        setCurrentPosition([]);
        currentPositions = [];

        const initPlay = () =>
        {
            cp = p.slice(0, routePositionIndex);
            setRoutePositionsPlay(cp);

            if(routePositionIndex > 0) setMapCenterPosition({lat: cp[cp.length - 1].latitude, lng: cp[cp.length - 1].longitude});

            if(routePositionIndex > p.length - 1)
            {
                clearInterval(routePositionTimer);
                routePositionTimer = 0;
                routePositionIndex = 0;

                setIsPlay(true);
            }

            routePositionIndex += 1;
            setRoutePositionsPlayIndex(routePositionIndex);
        }

        initPlay();
        routePositionTimer = setInterval(initPlay, 1000);
    }

    const handlePause = () =>
    {
        if(routePositionTimer > 0)
        {
            clearInterval(routePositionTimer);
            routePositionTimer = 0;
        }

        setIsPlay(false);
    }

    function handleOpenPopup(e:any)
    {
        if(openPopup) e.target.openPopup();
    }

    function handleHidePopup()
    {
        setOpenPopup(false);
    }

    function handleShowPopup()
    {
        setOpenPopup(true);
    }

    // map slide
    const handleMapSlideChange = (e: any, v: number | number[]) =>
    {
        if (typeof v === 'number')
        {
            handlePause();
            handlePlay(v);
        }
    }

    // current position
    useEffect
    (
        () =>
        {
            if(currentPositionTimer > 0)
            {
                clearInterval(currentPositionTimer);
                currentPositionTimer = 0;
            }
            if(currentDevice != null) handleCurrentPosition();
        },
        [currentDevice]
    );

    const handleCurrentPosition = () =>
    {
        if(!(currentPositionTimer > 0))
        {
            runCurrentPosition(true);

            currentPositionTimer = setInterval
            (
                function ()
                {
                    runCurrentPosition(false);
                },
                15000
            );
        }
    }

    const runCurrentPosition = (showLoading: boolean) =>
    {
        setRoutePositions(null);
        setRoutePositionsPlay(null);

        if(currentDevice != null) getCurrentPosition(currentDevice.id, showLoading);

        if(routePositionTimer > 0)
        {
            clearInterval(routePositionTimer);
            routePositionTimer = 0;
        }
    }

    // filter date
    const handleDialogFilterDateClose = () =>
    {
        setDialogFilterDateIsOpen(false);
    }

    const handleDialogFilterDate = () =>
    {
        if(filterDate1 !== "" && filterDate2 !== "" && filterTime1 !== "" && filterTime2 !== "")
        {
            let fd1 = new Date(filterDate1);
            let ft1 = new Date(filterTime1);
            let fdt1 = fd1.getFullYear() + "-" + helpers.inNumber(fd1.getMonth() + 1) + "-" + helpers.inNumber(fd1.getDate()) + "T" + helpers.inNumber(ft1.getHours()) + ":" + helpers.inNumber(ft1.getMinutes()) + ":" + helpers.inNumber(ft1.getSeconds()) + "Z";

            let fd2 = new Date(filterDate2);
            let ft2 = new Date(filterTime2);
            let fdt2 = fd2.getFullYear() + "-" + helpers.inNumber(fd2.getMonth() + 1) + "-" + helpers.inNumber(fd2.getDate()) + "T" + helpers.inNumber(ft2.getHours()) + ":" + helpers.inNumber(ft2.getMinutes()) + ":" + helpers.inNumber(ft2.getSeconds()) + "Z";

            getPositions(fdt1, fdt2);
            setDialogFilterDateIsOpen(false);
        }

        setFilterDate1("");
        setFilterDate2("");
        setFilterTime1("");
        setFilterTime2("");
    }

    // back
    const handleBack = () =>
    {
        navigate(-1);
    }

    //--------------------
    return (
        <div className="page-tracking">
            {/* header */}
            <header>
                {
                    devices != null && devices.length > 0 ?
                        <div className="current-device t2">
                            <span className="title">خودرو:</span>
                            <span className="car-name">{currentDevice.name}</span>
                            <div className="btn-change">
                                <Button className="btn-title" onClick={handleOpenDeviceItems}>(تغییر خودرو)</Button>
                                <Menu anchorEl={anchorEl} open={openDeviceItems} onClose={handleCloseDeviceItems} className="device-menu-items">
                                    {
                                        devices.map
                                        (
                                            (item: any, index: number) =>
                                            {
                                                return <MenuItem className="item" onClick={() => handleDeviceChange(item)} key={"dmi" + index}>{item.name}</MenuItem>
                                            }
                                        )
                                    }
                                </Menu>
                            </div>
                        </div>
                        :
                        <div></div>
                }

                <div className="btn-box">
                    <button type="button" className="btn-layer" onClick={handleOpenLayer}><LayersTwoToneIcon/></button>
                    <Menu anchorEl={anchorElLayer} open={openLayer} onClose={handleCloseLayer} className="filter-menu-items">
                        <MenuItem className="item" onClick={() => handleLayerChange(1)}>نقشه ماهواره</MenuItem>
                        <MenuItem className="item" onClick={() => handleLayerChange(2)}>نقشه عادی</MenuItem>
                    </Menu>

                    <button type="button" className="btn-current-position" onClick={handleCurrentPosition}><LocationSearchingIcon/></button>

                    <button type="button" className="btn-filter" onClick={handleOpenFilter}><RestoreTwoToneIcon/></button>
                    <Menu anchorEl={anchorElFilter} open={openFilter} onClose={handleCloseFilter} className="filter-menu-items">
                        <MenuItem className="item" onClick={() => handleFilterChange(1)}>امروز</MenuItem>
                        <MenuItem className="item" onClick={() => handleFilterChange(2)}>دیروز</MenuItem>
                        <MenuItem className="item" onClick={() => handleFilterChange(3)}>تاریخ و ساعت انتخابی</MenuItem>
                        <MenuItem className="item">
                            <FormControlLabel control={<Checkbox checked={showStopPoint} onClick={() => setShowStopPoint(!showStopPoint)}/>} label="نمایش نقاط توقف"/>
                        </MenuItem>
                        <MenuItem className="item">
                            <FormControlLabel control={<Checkbox checked={showDirectionSign} onClick={() => setShowDirectionSign(!showDirectionSign)}/>} label="نمایش جهت حرکت"/>
                        </MenuItem>
                    </Menu>

                    {userState.isTracking ? null : <button type="button" className="btn-back" onClick={handleBack}><KeyboardBackspaceTwoToneIcon/></button>}
                </div>
            </header>

            {/* map */}
            <MapContainer className="map" center={mapCenterPosition} zoom={18} scrollWheelZoom={false}>
                <TileLayer url={mapLayer}/>

                {/* current position */}
                {/*
                    currentPosition != null ?
                        <Marker position={{lat: currentPosition.latitude, lng: currentPosition.longitude}} icon={iconCar}>
                            <Popup className="car-popup">
                                <div className="car-popup-item"><span className="title">سرعت</span><span className="value">{currentPosition.speed} km</span></div>
                                <div className="car-popup-item"><span className="title">زمان</span><span className="value">{helpers.initDateTime(currentPosition.fixTime)}</span></div>

                                {currentPosition.attributes.charge !== undefined ? <div className="car-popup-item"><span className="title">شارژ</span><span className="value">{currentPosition.attributes.charge ? 'بله' : 'خیر'}</span></div> : null}
                                {currentPosition.attributes.blocked !== undefined ? <div className="car-popup-item"><span className="title">بلوکه</span><span className="value">{currentPosition.attributes.blocked ? 'بله' : 'خیر'}</span></div> : null}
                                {currentPosition.attributes.batteryLevel !== undefined ? <div className="car-popup-item"><span className="title">باطری</span><span className="value">{currentPosition.attributes.batteryLevel}%</span></div> : null}
                                {currentPosition.attributes.motion !== undefined ? <div className="car-popup-item"><span className="title">حرکت</span><span className="value">{currentPosition.attributes.motion ? 'بله' : 'خیر'}</span></div> : null}
                                {currentPosition.attributes.ignition !== undefined ? <div className="car-popup-item"><span className="title">روشن</span><span className="value">{currentPosition.attributes.ignition ? 'بله' : 'خیر'}</span></div> : null}
                                {currentPosition.attributes.door !== undefined ? <div className="car-popup-item"><span className="title">درب</span><span className="value">{currentPosition.attributes.door ? 'باز' : 'بسته'}</span></div> : null}
                            </Popup>
                        </Marker>
                        :
                        null
                */}
                {
                    currentPosition != null && currentPosition.length > 0 ?
                        currentPosition.map
                        (
                            (item:any, index:number) =>
                            {
                                return<>
                                    {
                                        (index < (currentPosition.length - 1)) ?
                                            <Polyline key={"polp" + index} pathOptions={{ color: 'blue', weight: 3, dashArray: '20, 6', lineCap: 'square' }} positions={[[currentPosition[index].latitude, currentPosition[index].longitude], [currentPosition[index + 1].latitude, currentPosition[index + 1].longitude]]} />
                                            :
                                            null
                                    }

                                    {
                                        index === currentPosition.length - 1 ?
                                            <Marker key={"mark" + index} position={{lat: item.latitude, lng: item.longitude}} icon={iconCar}>
                                                <Popup className="car-popup">
                                                    <div className="car-popup-item"><span className="title">سرعت</span><span className="value">{helpers.initSpeed(item.speed)} km</span></div>
                                                    <div className="car-popup-item"><span className="title">زمان</span><span className="value">{helpers.initDateTime(item.fixTime)}</span></div>

                                                    {item.attributes.charge !== undefined ? <div className="car-popup-item"><span className="title">شارژ</span><span className="value">{item.attributes.charge ? 'بله' : 'خیر'}</span></div> : null}
                                                    {item.attributes.blocked !== undefined ? <div className="car-popup-item"><span className="title">بلوکه</span><span className="value">{item.attributes.blocked ? 'بله' : 'خیر'}</span></div> : null}
                                                    {item.attributes.batteryLevel !== undefined ? <div className="car-popup-item"><span className="title">باطری</span><span className="value">{item.attributes.batteryLevel}%</span></div> : null}
                                                    {item.attributes.motion !== undefined ? <div className="car-popup-item"><span className="title">حرکت</span><span className="value">{item.attributes.motion ? 'بله' : 'خیر'}</span></div> : null}
                                                    {item.attributes.ignition !== undefined ? <div className="car-popup-item"><span className="title">روشن</span><span className="value">{item.attributes.ignition ? 'بله' : 'خیر'}</span></div> : null}
                                                    {item.attributes.door !== undefined ? <div className="car-popup-item"><span className="title">درب</span><span className="value">{item.attributes.door ? 'باز' : 'بسته'}</span></div> : null}
                                                    {item.attributes.fuel1 !== undefined ? <div className="car-popup-item"><span className="title">دما</span><span className="value">{item.attributes.fuel1}</span></div> : null}
                                                </Popup>
                                            </Marker>
                                            :
                                            null
                                    }
                                </>
                            }
                        )
                        :
                        null
                }

                {/* route positions & marker */}
                {
                    /*routePositions != null && routePositions.length > 0 && (routePositionsPlay == null || routePositionsPlay.length === 0) ?*/
                    routePositions != null && routePositions.length > 0 ?
                        routePositions.map
                        (
                            (item:any, index:number) =>
                            {
                                return<>
                                    {
                                        (index < (routePositions.length - 1)) ?
                                            <Polyline key={"pol" + index} pathOptions={{ color: 'red', weight: 3, dashArray: '20, 6', lineCap: 'square' }} positions={[[routePositions[index].latitude, routePositions[index].longitude], [routePositions[index + 1].latitude, routePositions[index + 1].longitude]]} />
                                            :
                                            null
                                    }

                                    <Marker key={"mark" + index} position={{lat: item.latitude, lng: item.longitude}} icon={(index === 0 || index >= routePositions.length - 1) ? iconCar : (showDirectionSign ? iconDirectionSign[item.course] : iconPoint)}>
                                        <Popup className="car-popup">
                                            <div className="car-popup-item"><span className="title">سرعت</span><span className="value">{helpers.initSpeed(item.speed)} km</span></div>
                                            <div className="car-popup-item"><span className="title">زمان</span><span className="value">{helpers.initDateTime(item.fixTime)}</span></div>

                                            {item.attributes.charge !== undefined ? <div className="car-popup-item"><span className="title">شارژ</span><span className="value">{item.attributes.charge ? 'بله' : 'خیر'}</span></div> : null}
                                            {item.attributes.blocked !== undefined ? <div className="car-popup-item"><span className="title">بلوکه</span><span className="value">{item.attributes.blocked ? 'بله' : 'خیر'}</span></div> : null}
                                            {item.attributes.batteryLevel !== undefined ? <div className="car-popup-item"><span className="title">باطری</span><span className="value">{item.attributes.batteryLevel}%</span></div> : null}
                                            {item.attributes.motion !== undefined ? <div className="car-popup-item"><span className="title">حرکت</span><span className="value">{item.attributes.motion ? 'بله' : 'خیر'}</span></div> : null}
                                            {item.attributes.ignition !== undefined ? <div className="car-popup-item"><span className="title">روشن</span><span className="value">{item.attributes.ignition ? 'بله' : 'خیر'}</span></div> : null}
                                            {item.attributes.door !== undefined ? <div className="car-popup-item"><span className="title">درب</span><span className="value">{item.attributes.door ? 'باز' : 'بسته'}</span></div> : null}
                                            {item.attributes.fuel1 !== undefined ? <div className="car-popup-item"><span className="title">دما</span><span className="value">{item.attributes.fuel1}</span></div> : null}
                                        </Popup>

                                        {
                                            index === 0 ?
                                                <Tooltip direction="top" opacity={1} permanent className="car-popup-start">نقطه شروع</Tooltip>
                                                :
                                                (
                                                    index >= routePositions.length - 1 ?
                                                        <Tooltip direction="top" opacity={1} permanent className="car-popup-end">نقطه پایان</Tooltip>
                                                        :
                                                        null
                                                )
                                        }
                                    </Marker>
                                </>
                            }
                        )
                        :
                        null
                }

                {/* route positions play */}
                {
                    routePositionsPlay != null && routePositionsPlay.length > 1 ?
                        routePositionsPlay.map
                        (
                            (item:any, index:number) =>
                            {
                                return<>
                                    {
                                        (index < (routePositionsPlay.length - 1)) ?
                                            <Polyline key={"polp" + index} pathOptions={{ color: 'blue', weight: 3, dashArray: '20, 6', lineCap: 'square' }} positions={[[routePositionsPlay[index].latitude, routePositionsPlay[index].longitude], [routePositionsPlay[index + 1].latitude, routePositionsPlay[index + 1].longitude]]} />
                                            :
                                            null
                                    }

                                    {
                                        index >= routePositionsPlay.length - 1 ?
                                            <Marker eventHandlers={{ add: handleOpenPopup, popupclose: handleHidePopup, popupopen: handleShowPopup }} position={{lat: item.latitude, lng: item.longitude}} icon={iconCar}>
                                                {/*<Popup autoClose={false} closeOnClick={false} className="car-popup">*/}
                                                {/*    <div className="car-popup-item"><span className="title">سرعت</span><span className="value">{helpers.initSpeed(item.speed)} km</span></div>*/}
                                                {/*    <div className="car-popup-item"><span className="title">زمان</span><span className="value">{helpers.initDateTime(item.fixTime)}</span></div>*/}
                                                {/*</Popup>*/}

                                                {/*<Tooltip direction="top" opacity={1} permanent className="car-popup">*/}
                                                {/*    <div className="car-popup-inner">*/}
                                                {/*        <div className="car-popup-item"><span className="title">سرعت</span><span className="value">{item.speed} km</span></div>*/}
                                                {/*        <div className="car-popup-item"><span className="title">زمان</span><span className="value">{helpers.initDateTime(item.fixTime)}</span></div>*/}
                                                {/*    </div>*/}
                                                {/*</Tooltip>*/}
                                            </Marker>
                                            :
                                            null
                                    }
                                </>
                            }
                        )
                        :
                        null
                }

                <FlyMapTo position={mapCenterPosition}/>
            </MapContainer>

            {/* play position information */}
            {
                routePositionsPlay != null && routePositionsPlay.length > 1 ?
                    <div className="car-information">
                        <div className="item"><span className="title">سرعت</span><span className="value">{routePositionsPlay[routePositionsPlay.length - 1].speed} km</span></div>
                        <div className="item"><span className="title">زمان</span><span className="value">{helpers.initDateTime(routePositionsPlay[routePositionsPlay.length - 1].fixTime)}</span></div>

                        {routePositionsPlay[routePositionsPlay.length - 1].attributes.charge !== undefined ? <div className="car-popup-item"><span className="title">شارژ</span><span className="value">{routePositionsPlay[routePositionsPlay.length - 1].attributes.charge ? 'بله' : 'خیر'}</span></div> : null}
                        {routePositionsPlay[routePositionsPlay.length - 1].attributes.blocked !== undefined ? <div className="car-popup-item"><span className="title">بلوکه</span><span className="value">{routePositionsPlay[routePositionsPlay.length - 1].attributes.blocked ? 'بله' : 'خیر'}</span></div> : null}
                        {routePositionsPlay[routePositionsPlay.length - 1].attributes.batteryLevel !== undefined ? <div className="car-popup-item"><span className="title">باطری</span><span className="value">{routePositionsPlay[routePositionsPlay.length - 1].attributes.batteryLevel}%</span></div> : null}
                        {routePositionsPlay[routePositionsPlay.length - 1].attributes.motion !== undefined ? <div className="car-popup-item"><span className="title">حرکت</span><span className="value">{routePositionsPlay[routePositionsPlay.length - 1].attributes.motion ? 'بله' : 'خیر'}</span></div> : null}
                        {routePositionsPlay[routePositionsPlay.length - 1].attributes.ignition !== undefined ? <div className="car-popup-item"><span className="title">روشن</span><span className="value">{routePositionsPlay[routePositionsPlay.length - 1].attributes.ignition ? 'بله' : 'خیر'}</span></div> : null}
                        {routePositionsPlay[routePositionsPlay.length - 1].attributes.door !== undefined ? <div className="car-popup-item"><span className="title">درب</span><span className="value">{routePositionsPlay[routePositionsPlay.length - 1].attributes.door ? 'باز' : 'بسته'}</span></div> : null}
                        {routePositionsPlay[routePositionsPlay.length - 1].attributes.fuel1 !== undefined ? <div className="car-popup-item"><span className="title">دما</span><span className="value">{routePositionsPlay[routePositionsPlay.length - 1].attributes.fuel1}</span></div> : null}
                    </div>
                    :
                    null
            }

            {/* map slider */}
            {
                routePositions != null && routePositions.length > 1 && isPlay ?
                    <Slider value={routePositionsPlayIndex} min={0} max={routePositions.length} aria-label="Default" valueLabelDisplay="auto" className="map-slider" onChangeCommitted={handleMapSlideChange}/>
                    :
                    null
            }

            {/* btn play - pause */}
            {
                routePositions != null && routePositions.length > 1 ?
                    <div className="btn-play-pause">
                        {
                            isPlay ?
                                <button className="btn btn-pause" onClick={handlePause}><PauseCircleIcon className="icon"/></button>
                                :
                                <button className="btn btn-play" onClick={() => handlePlay()}><PlayCircleIcon className="icon"/></button>
                        }
                    </div>
                    :
                    null
            }

            {/* modal filter date */}
            <Dialog open={dialogFilterDateIsOpen} onClose={handleDialogFilterDateClose}>
                <DialogContent className="dialog-filter-date">
                    <div className="row">
                        <MobileDatePicker localeText={{ okButtonLabel: 'تایید' }} label="از تاریخ" onChange={(e) => { if(e != null) setFilterDate1(e.toString()); }} />
                        <MobileTimePicker localeText={{ okButtonLabel: 'تایید' }} ampm={false} label="از ساعت" onChange={(e) => { if(e != null) setFilterTime1(e.toString()); }} />
                    </div>

                    <div className="row">
                        <MobileDatePicker localeText={{ okButtonLabel: 'تایید' }} label="تا تاریخ" onChange={(e) => { if(e != null) setFilterDate2(e.toString()); }} />
                        <MobileTimePicker localeText={{ okButtonLabel: 'تایید' }} ampm={false} label="تا ساعت" onChange={(e) => { if(e != null) setFilterTime2(e.toString()); }} />
                    </div>
                </DialogContent>

                <DialogActions>
                    <Button size="medium" variant="outlined" onClick={handleDialogFilterDateClose}>لغو</Button>
                    <Button size="medium" variant="contained" color="success" onClick={handleDialogFilterDate}>اعمال فیلتر</Button>
                </DialogActions>
            </Dialog>

            {/* loading */}
            {isLoading ? <LoadingOver/> : null}
        </div>
    );
}

export default Index;

//********************************************************** map center

function FlyMapTo(props:{position: any})
{
    const map = useMap()

    useEffect
    (
        () =>
        {
            map.flyTo(props.position);
        },
        [map, props.position]
    );

    return null;
}

