
function PageNotFound(props)
{


    //
    return (
        <>page NotFound</>
    );
}

export default PageNotFound;
