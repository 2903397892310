import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import {store} from './states/store';
import {createTheme, Theme, ThemeProvider} from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFnsJalali } from '@mui/x-date-pickers/AdapterDateFnsJalaliV3';
import { faIR } from '@mui/x-date-pickers/locales';
// @ts-ignore
import { prefixer } from 'stylis';
import rtlPlugin from 'stylis-plugin-rtl';
import { CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';

//-----------------------------------------------

const theme:Theme = createTheme
(
    {
        direction: 'rtl',
        typography:
            {
                fontFamily: ['IranYekan'].join(','),
            },
    },
    faIR
);

const cacheRtl = createCache
(
    {
        key: 'muirtl',
        stylisPlugins: [prefixer, rtlPlugin],
    }
);

//-----------------------------------------------

const root = ReactDOM.createRoot
(
    document.getElementById('root') as HTMLElement
);
root.render(
    <Provider store={store}>
        <ThemeProvider theme={theme}>
            <CacheProvider value={cacheRtl}>
                <LocalizationProvider dateAdapter={AdapterDateFnsJalali}>
                    <App />
                </LocalizationProvider>
            </CacheProvider>
        </ThemeProvider>
    </Provider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
