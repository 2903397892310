import React, {useEffect, useState} from 'react';
import Template from "../../layouts/Template";
import "./deviceNumber.scss";
import {TextField} from "@mui/material";
import {useAppSelector} from "../../states/hooks";
import CurrentDevice from "../../components/CurrentDevice";
import {LoadingButton} from "@mui/lab";
import DialogAlert from "../../components/DialogAlert";
import IndexedDb from "../../database/indexedDb";
import {tableDevice, tableNames} from "../../database/interfaceDb";
import {SubmitHandler, useForm} from "react-hook-form";
import helpers from "../../utils/helpers";

//**********************************************************

interface FormDeviceInput
{
    mobile: string;
}

let indexedDb: IndexedDb;
let device: tableDevice;

//**********************************************************

function DeviceNumber()
{
    const currentDeviceState = useAppSelector(state => state.deviceState);

    //
    const dialogAlert = React.createRef<any>();
    const [dialogAlertType, setDialogAlertType] = useState<string>('');
    const [dialogAlertTitle, setDialogAlertTitle] = useState<string>('');
    const [dialogAlertDes, setDialogAlertDes] = useState<string>('');

    const [formDeviceMobile, setFormDeviceMobile] = useState<string>('');
    const [isSaving, setIsSaving] = useState<boolean>(false);

    // open db
    const initDB = () =>
    {
        indexedDb = new IndexedDb();
        indexedDb.open().then(r => {});
    };
    useEffect(() => { initDB(); }, []);

    //
    useEffect
    (
        () =>
        {
            device = currentDeviceState;

            // set form data
            setFormDeviceMobile(device.mobile);

            setValue('mobile', device.mobile);
        },
        [currentDeviceState]
    );

    // form
    const { register, formState: { errors }, handleSubmit , setValue} = useForm<FormDeviceInput>({defaultValues: { mobile: '' }})
    const onSubmit: SubmitHandler<FormDeviceInput> = async (formData:FormDeviceInput) =>
    {
        const da = dialogAlert.current;
        let errorMessage:string = "";
        setIsSaving(true);

        // save device in db
        let tmpFormData = {id: device.id, mobile: helpers.initMobile(formData.mobile), carName: device.carName, deviceType: device.deviceType, selected: 1};
        indexedDb.putValue(tableNames.Devices, tmpFormData).then
        (
            status =>
            {
                if(status)
                {
                    setDialogAlertTitle("");
                    setDialogAlertDes("اطلاعات با موفقیت ثبت شده است.");
                    setDialogAlertType("success");
                    da.show();
                }
                else
                {
                    errorMessage = "مشکلی در ثبت اطلاعات پیش آمده است.";
                }
            }
        ).catch
        (
            function(error)
            {
                errorMessage = error;
            }
        ).finally
        (
            function()
            {
                setIsSaving(false);

                if(errorMessage !== "")
                {
                    setDialogAlertTitle("");
                    setDialogAlertDes(errorMessage);
                    setDialogAlertType("error");
                    da.show();
                }
            }
        );
    }

    //--------------------
    return (
        <Template pageClass="page-device-number" pageTitle="تغییر شماره سیم کارت دستگاه">
            <CurrentDevice />

            {/* --- form --- */}
            <form className="form" onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off">
                <TextField label="شماره سیم کارت" id="formDeviceMobile" variant="filled" autoComplete="new-mobile" dir="ltr" {...register("mobile", {required: true})} error={errors.mobile?.type === "required"} value={formDeviceMobile} onChange={(e) => setFormDeviceMobile(e.target.value)} />

                <LoadingButton type="submit" fullWidth={true} size="large" variant="contained" color="success" loading={isSaving} disabled={isSaving}>ثبت</LoadingButton>
            </form>

            {/* --- --- --- */}
            <DialogAlert ref={dialogAlert} title={dialogAlertTitle} des={dialogAlertDes} type={dialogAlertType} isClose={true} />
        </Template>
    );
}

export default DeviceNumber;
