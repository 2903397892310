import React, { Suspense, lazy } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import RouterPublic from "./RouterPublic";
import RouterPrivate from "./RouterPrivate";

//**********************************************************

// const Home = lazy(() => import('../pages/Home'));
// const Login = lazy(() => import('../pages/Login'));
// const Teams = lazy(() => import('../pages/Team'));
// const TeamView = lazy(() => import('../pages/Team/view'));
// const Competition = lazy(() => import('../pages/Competition'));
// const Donate = lazy(() => import('../pages/Donate'));
// const Contact = lazy(() => import('../pages/Contact'));
// const PageNotFound = lazy(() => import('../pages/PageNotFound'));
// const InstallIndex:React.LazyExoticComponent<any> = lazy(() => import('../pages/Install'));
// const ServerIndex:React.LazyExoticComponent<any> = lazy(() => import('../pages/Server'));
// const SplashIndex:React.LazyExoticComponent<any> = lazy(() => import('../pages/Splash'));
// const Devices:React.LazyExoticComponent<any> = lazy(() => import('../pages/Device'));
// const DeviceAddEdit:React.LazyExoticComponent<any> = lazy(() => import('../pages/Device/addEdit'));
// const About:React.LazyExoticComponent<any> = lazy(() => import('../pages/About'));
// const Sms:React.LazyExoticComponent<any> = lazy(() => import('../pages/Sms'));
// const Setting:React.LazyExoticComponent<any> = lazy(() => import('../pages/Setting'));
// const ResetPassword:React.LazyExoticComponent<any> = lazy(() => import('../pages/Setting/resetPassword'));
// const ResetDevice:React.LazyExoticComponent<any> = lazy(() => import('../pages/Setting/resetDevice'));
// const DeviceNumber:React.LazyExoticComponent<any> = lazy(() => import('../pages/Setting/deviceNumber'));
// const PointDistance:React.LazyExoticComponent<any> = lazy(() => import('../pages/Setting/pointDistance'));
// const CentralNumber:React.LazyExoticComponent<any> = lazy(() => import('../pages/Setting/centralNumber'));
// const DeviceAdmin:React.LazyExoticComponent<any> = lazy(() => import('../pages/Setting/deviceAdmin'));
// const Tracking:React.LazyExoticComponent<any> = lazy(() => import('../pages/Tracking'));

import Home from '../pages/Home';
import Login from '../pages/Login';
import PageNotFound from '../pages/PageNotFound';
import InstallIndex from '../pages/Install';
import ServerIndex from '../pages/Server';
import SplashIndex from '../pages/Splash';
import Devices from '../pages/Device';
import DeviceAddEdit from '../pages/Device/addEdit';
import About from '../pages/About';
import Sms from '../pages/Sms';
import Setting from '../pages/Setting';
import ResetPassword from '../pages/Setting/resetPassword';
import ResetDevice from '../pages/Setting/resetDevice';
import DeviceNumber from '../pages/Setting/deviceNumber';
import PointDistance from '../pages/Setting/pointDistance';
import CentralNumber from '../pages/Setting/centralNumber';
import DeviceAdmin from '../pages/Setting/deviceAdmin';
import Tracking from '../pages/Tracking';

//**********************************************************

function Router()
{
    return (
        <BrowserRouter>
            {/*//     <Suspense>*/}
            <Routes>
                <Route path="/splash" element={<SplashIndex />} />

                <Route element={<RouterPublic />}>
                    <Route path="/" element={<InstallIndex />} />
                    <Route path="/install" element={<InstallIndex />} />
                    <Route path="/server" element={<ServerIndex />} />
                    <Route path="/login" element={<Login />} />
                </Route>

                <Route element={<RouterPrivate />}>
                    <Route path="/home" element={<Home />} />
                    <Route path="/about" element={<About />} />
                    <Route path="/devices" element={<Devices />} />
                    <Route path="/device/add" element={<DeviceAddEdit />} />
                    <Route path="/device/edit/:deviceId" element={<DeviceAddEdit />} />
                    <Route path="/sms" element={<Sms />} />
                    <Route path="/tracking" element={<Tracking />} />

                    <Route path="/setting" element={<Setting />} />
                    <Route path="/setting/reset-password" element={<ResetPassword />} />
                    <Route path="/setting/reset-device" element={<ResetDevice />} />
                    <Route path="/setting/device-number" element={<DeviceNumber />} />
                    <Route path="/setting/point-distance" element={<PointDistance />} />
                    <Route path="/setting/central-number" element={<CentralNumber />} />
                    <Route path="/setting/device-admin" element={<DeviceAdmin />} />
                </Route>

                <Route path="*" element={<PageNotFound />} />
            </Routes>
            {/*// </Suspense>*/}
        </BrowserRouter>
    );
}

export default Router;
