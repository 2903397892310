import { createSlice } from '@reduxjs/toolkit';

//**********************************************************

// define a type for the slice state
interface device
{
    id: number;
    mobile: string;
    carName: string;
    deviceType: string;
    selected: number;
}

//**********************************************************

export const deviceState = createSlice
(
    {
        name: 'deviceState',
        initialState:
            {
                id: 0,
                mobile: '',
                carName: '',
                deviceType: '',
                selected: 0,
            },
        reducers:
            {
                save: (state, action) =>
                {
                    state.id = action.payload.id;
                    state.mobile = action.payload.mobile;
                    state.carName = action.payload.carName;
                    state.deviceType = action.payload.deviceType;
                    state.selected = action.payload.selected;
                },
            },
    }
);

// Action creators are generated for each case reducer function
export const { save } = deviceState.actions;

export default deviceState.reducer;
