export default
{
    Concox:
        {
            activeSystem: {code: 'CENTER,A,Mobile#', title: 'فعال کردن سیستم'},
            inactiveSystem: {code: 'CENTER,D#', title: 'غیر فعال کردن سیستم'},
            currentLocation: {code: 'URL#', title: 'دریافت موقعیت'},
            
            turnOff: {code: {on: 'RELAY,1#', off: 'RELAY,0#'}, title: 'خاموش کردن خودرو'},
            alarmSpeed: {code: {on: 'SPEED,ON,5,MaxSpeed,1#', off: 'SPEED,OFF#'}, title: 'هشدار سرعت'},
            alarmDisconnectBattery: {code: {on: 'POWERALM,ON,2,5,30#', off: 'POWERALM,OFF#'}, title: 'هشدار جدا شدن سر باطری'},
            alarmDoor: {code: {on: 'DOORALM,ON,1#', on2: 'DOORALM,ON,2#', off: 'DOORALM,OFF#'}, title: 'هشدار سنسور درب'},
            alarmImpact: {code: {on: 'SENALM,ON,1#', on2: 'SENALM,ON,2#', off: 'SENALM,OFF#'}, title: 'هشدار سنسور ضربه'},
            alarmMoving: {code: {on: 'MOVING,ON,Distance,1#', on2: 'MOVING,ON,Distance,2#', off: 'MOVING,OFF#'}, title: 'هشدار حرکت'},
            alarmTurnOn: {code: {on: 'ACCALM,ON,1#', on2: 'ACCALM,ON,3#', off: 'ACCALM,OFF#'}, title: 'هشدار روشن شدن خودرو'},
            alarmTurnOff: {code: {on: 'ACCOFFALM,ON,1#', on2: 'ACCOFFALM,ON,3#', off: 'ACCOFFALM,OFF#'}, title: 'هشدار خاموش شدن خودرو'},
            
            resetDevice: {code: {step1: 'factory#', step2: 'server,1,2.radyab1.ir,5023,0#'}, title: 'راه اندازی مجدد دستگاه'},

            centralNumber: {code: {add: "CENTER,ANumber,Mobile#", remove: "CENTER,DNumber#"}, title: 'شماره مرکزی'},
            deviceAdmin: {code: {add: "SOS,A,Mobile1,Mobile2,Mobile3#", remove: "SOS,D,1,2,3#"}, title: 'ادمین دستگاه'}
        },
    
    Coban:
        {
            activeSystem: {code: 'Arm123456', title: 'فعال کردن سیستم'},
            inactiveSystem: {code: 'Disarm123456', title: 'غیر فعال کردن سیستم'},
            activeListen: {code: 'Monitor123456', title: 'شنود'},
            inactiveListen: {code: 'Tracker123456', title: 'لغو شنود'},
            
            turnOff: {code: {on: 'STOP123456', off: 'resume123456'}, title: 'خاموش کردن خودرو'},
            alarmSpeed: {code: {on: 'Speed123456 MaxSpeed', off: 'Nospeed123456'}, title: 'هشدار سرعت'},
            alarmMoving: {code: {on: 'Move123456', off: 'Nomove123456'}, title: 'هشدار حرکت'},
            alarmTurnOn: {code: {on: 'ACC123456', off: 'noacc123456'}, title: 'هشدار روشن شدن خودرو'},
            alarmSOS: {code: 'Sos123456 ReceiverMobile', title: 'پیامک هشدار SOS'},

            resetDevice: {code: 'Reset123456', title: 'راه اندازی مجدد دستگاه'},

            pointDistance: {code: 'fixSecondsMinutem***n123456', title: 'تنظیم فاصله ارسال نقطه'},

            deviceAdmin: {code: {add: "admin123456 Mobile1", remove: "noadmin123456 Mobile1"}, title: 'ادمین دستگاه'}
        },
};
