import { configureStore } from '@reduxjs/toolkit';
import userStateReducer from './userState';
import deviceStateReducer from './deviceState';

//**********************************************************

export const store = configureStore
({
    reducer:
        {
            userState: userStateReducer,
            deviceState: deviceStateReducer,
        }
});

export type AppStore = typeof store;
export type RootState = ReturnType<AppStore['getState']>;
export type AppDispatch = AppStore['dispatch'];
