import React, {useState} from 'react';
import Template from "../../layouts/Template";
import "./deviceAdmin.scss";
import {Button, TextField} from "@mui/material";
import {useAppSelector} from "../../states/hooks";
import CurrentDevice from "../../components/CurrentDevice";
import DeleteTwoToneIcon from "@mui/icons-material/DeleteTwoTone";
import SendTwoToneIcon from '@mui/icons-material/SendTwoTone';
import smsDevice from "../../utils/smsDevice";
import helpers from "../../utils/helpers";

//**********************************************************

function DeviceAdmin()
{
    const currentDeviceState = useAppSelector(state => state.deviceState);

    const [deviceAdminMobile1, setDeviceAdminMobile1] = useState<string>('');
    const [deviceAdminMobile2, setDeviceAdminMobile2] = useState<string>('');
    const [deviceAdminMobile3, setDeviceAdminMobile3] = useState<string>('');

    // send sms
    const handleSendSms = (type: string) =>
    {
        let smsCode = "";

        if(currentDeviceState.deviceType === "Concox")
        {
            switch(type)
            {
                case "add": smsCode = smsDevice.Concox.deviceAdmin.code.add; break;
                case "remove": smsCode = smsDevice.Concox.deviceAdmin.code.remove; break;
            }
        }
        else
        {
            switch(type)
            {
                case "add": smsCode = smsDevice.Coban.deviceAdmin.code.add; break;
                case "remove": smsCode = smsDevice.Coban.deviceAdmin.code.remove; break;
            }
        }

        if(deviceAdminMobile1 !== "")
        {
            // window.open("sms:" + currentDeviceState.mobile + "?&body=" + smsCode.replace('Mobile1', helpers.initMobile(deviceAdminMobile1)).replace('Mobile2', helpers.initMobile(deviceAdminMobile2)).replace('Mobile3', helpers.initMobile(deviceAdminMobile3)));
            helpers.sendSms(currentDeviceState.mobile, smsCode.replace('Mobile1', helpers.initMobile(deviceAdminMobile1)).replace('Mobile2', helpers.initMobile(deviceAdminMobile2)).replace('Mobile3', helpers.initMobile(deviceAdminMobile3)));
        }
    }

    //--------------------
    return (
        <Template pageClass="page-device-admin" pageTitle="ادمین دستگاه">
            <CurrentDevice/>

            <div className="des">شماره های ادمین برای شنیدن صدا، دریافت هشدارهای جدا شدن سر باتری، سرعت غیر مجاز و حرکت می باشد.</div>

            <div className="form">
                <TextField label={currentDeviceState.deviceType === "Concox" ? "شماره 1" : "شماره"} variant="filled" autoComplete="new-mobile-1" dir="ltr" value={deviceAdminMobile1} onChange={(e) => setDeviceAdminMobile1(e.target.value)}/>
                {
                    currentDeviceState.deviceType === "Concox" ?
                        <>
                            <TextField label="شماره 2" variant="filled" autoComplete="new-mobile-2" dir="ltr" value={deviceAdminMobile2} onChange={(e) => setDeviceAdminMobile2(e.target.value)}/>
                            <TextField label="شماره 3" variant="filled" autoComplete="new-mobile-3" dir="ltr" value={deviceAdminMobile3} onChange={(e) => setDeviceAdminMobile3(e.target.value)}/>
                        </>
                        :
                        null
                }

                <div className="btn-box">
                    <Button variant="contained" color="success" className="btn" onClick={() => handleSendSms("add")}><SendTwoToneIcon className="icon"/> ثبت ادمین</Button>
                    <Button variant="contained" color="error" className="btn" onClick={() => handleSendSms("remove")}><DeleteTwoToneIcon className="icon"/> حذف ادمین</Button>
                </div>
            </div>
        </Template>
    );
}

export default DeviceAdmin;
