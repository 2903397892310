import { createSlice } from '@reduxjs/toolkit';

//**********************************************************

// define a type for the slice state
interface user
{
    email: string,
    password: string,
    isTracking: boolean,
}

//**********************************************************

export const userState = createSlice
(
    {
        name: 'userState',
        initialState:
            {
                email: '',
                password: '',
                isTracking: false,
            },
        reducers:
            {
                get: (state) =>
                {
                    let ud = JSON.parse(String(sessionStorage.getItem(String(process.env.REACT_APP_USER_AUTH_NAME))));

                    if(ud !== null)
                    {
                        state.email = ud.email;
                        state.password = ud.password;
                        state.isTracking = ud.isTracking;
                    }
                },
                save: (state, action) =>
                {
                    state.email = action.payload.email;
                    state.password = action.payload.password;
                    state.isTracking = action.payload.isTracking;

                    sessionStorage.setItem(String(process.env.REACT_APP_USER_AUTH_NAME), JSON.stringify(action.payload));
                },
                remove: (state) =>
                {
                    state.email = '';
                    state.password = '';
                    state.isTracking = false;

                    sessionStorage.removeItem(String(process.env.REACT_APP_USER_AUTH_NAME));
                }
            },
    }
);

// Action creators are generated for each case reducer function
export const { get, save, remove } = userState.actions;
// export const selectUserData = (state: RootState) => state.userState;
export default userState.reducer;
