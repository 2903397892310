import React, {useEffect, useState} from 'react';
import Template from "../../layouts/Template";
import './index.scss';
import {List, ListItem, ListItemButton, ListItemText} from "@mui/material";
import {useNavigate} from "react-router-dom";
import IndexedDb from "../../database/indexedDb";
import {tableNames} from "../../database/interfaceDb";
import restApi from "../../api/restApi";
import LoadingOver from "../../components/LoadingOver";

//**********************************************************

let indexedDb: IndexedDb;

//**********************************************************

function Index()
{
    const navigate = useNavigate();
    const [servers, setServers] = useState<any>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    // open db
    const initDB = () =>
    {
        indexedDb = new IndexedDb();
        indexedDb.open().then(r =>
        {
            // check server selected
            checkTableServer().then(hasServer => { if(hasServer) navigate( "/login"); });
        });
    };
    useEffect(() => { initDB(); getServers(); }, []);

    //
    const getServers = () =>
    {
        setIsLoading(true);

        restApi.rest('get', '', null, null, 'https://www.cartracker.ir/app-db-online/taksa/servers.php').then
        (
            async function(response)
            {
                if(response != null && response.length > 0)
                {
                    setServers(response);
                }
                else
                {
                    //setServers([{"id":"1","name_server":"server 1","url_server":"https://radyabi24.ir","device_server":"2.radyabi24.ir"},{"id":"2","name_server":"server 2","url_server":"http://radyab1.ir","device_server":"2.radyab1.ir"},{"id":"11","name_server":"server 3","url_server":"https://demo4.traccar.org","device_server":"radyabi24"}]);
                }
            }
        ).catch
        (
            function(error)
            {
            }
        ).finally
        (
            function()
            {
                setIsLoading(false);
            }
        );
    };

    //
    const handleAddServer = async (index: number) =>
    {
        try
        {
            // save server url
            const res = await indexedDb.putValue(tableNames.Servers, {url: servers[index].url_server + "/api"});

            if(res !== null)
            {
                navigate("/login");
            }
        }
        catch (err: unknown) {}
    };

    // check server selected
    const checkTableServer = async () =>
    {
        const servers = await indexedDb.getAllValue(tableNames.Servers);
        return (servers.length > 0);
    };

    //--------------------
    return (
        <Template pageClass="page-server" showHeader={false} showFooter={false}>
            <div className="page-title">لطفا یک مورد از سرورهای زیر را انتخاب نمایید.</div>

            <List className="list-servers">
                {
                    servers != null && servers.length > 0 ?
                        servers.map
                        (
                            (item:any, index:number) =>
                            {
                                return(
                                    <ListItem disablePadding key={index}>
                                        <ListItemButton onClick={() => handleAddServer(index)}>
                                            <ListItemText primary={item.name_server}/>
                                        </ListItemButton>
                                    </ListItem>
                                )
                            }
                        )
                        :
                        null
                }
            </List>

            {/* loading */}
            {isLoading ? <LoadingOver/> : null}
        </Template>
    );
}

export default Index;
