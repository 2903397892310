import React, {useEffect, useState} from 'react';
import Template from "../../layouts/Template";
import './addEdit.scss';
import DialogAlert from "../../components/DialogAlert";
import {useNavigate, useParams} from "react-router-dom";
import {TextField, FormControl, InputLabel, Select, MenuItem, DialogTitle, DialogActions, Button, type DialogProps} from "@mui/material";
import {LoadingButton} from "@mui/lab";
import {SubmitHandler, useForm} from "react-hook-form";
import IndexedDb from "../../database/indexedDb";
import {tableDevice, tableNames} from "../../database/interfaceDb";
import helpers from "../../utils/helpers";
import Dialog from "@mui/material/Dialog/Dialog";
import smsDevice from "../../utils/smsDevice";

//**********************************************************

interface FormDeviceInput
{
    ownerMobile: string;
    mobile: string;
    carName: string;
    deviceType: string;
}

let indexedDb: IndexedDb;
let device: tableDevice;
let flagAdminClicked: boolean = false;
let flagCentralClicked: boolean = false;

//**********************************************************

function Add()
{
    // get url params
    let { deviceId } = useParams();

    //
    const navigate = useNavigate();
    const [redirectToHome, setRedirectToHome] = useState<boolean>(false);
    const [showFooter, setShowFooter] = useState<boolean>(false);
    const [showHeader, setShowHeader] = useState<boolean>(false);

    const dialogAlert = React.createRef<any>();
    const [dialogAlertType, setDialogAlertType] = useState<string>('');
    const [dialogAlertTitle, setDialogAlertTitle] = useState<string>('');
    const [dialogAlertDes, setDialogAlertDes] = useState<string>('');

    const [formDeviceOwnerMobile, setFormDeviceOwnerMobile] = useState<string>('');
    const [formDeviceMobile, setFormDeviceMobile] = useState<string>('');
    const [formDeviceCarName, setFormDeviceCarName] = useState<string>('');
    const [formDeviceType, setFormDeviceType] = useState<string>('');
    const [isSaving, setIsSaving] = useState<boolean>(false);

    const [dialogAdminCentralIsOpen, setDialogAdminCentralIsOpen] = useState(false);

    // open db
    const initDB = () =>
    {
        indexedDb = new IndexedDb();
        indexedDb.open().then
        (
            r =>
            {
                // check exist device
                checkExistDevice().then(status =>
                {
                    setShowHeader(status);
                    setShowFooter(status);
                    setRedirectToHome(!status);
                });

                // load data
                deviceLoadData();
            }
        );
    };
    useEffect(() => { initDB(); }, []);

    // load data
    const deviceLoadData = () =>
    {
        if (deviceId != null)
        {
            indexedDb.getValue(tableNames.Devices, parseInt(deviceId)).then
            (
                _device =>
                {
                    device = _device;
                    setFormDeviceOwnerMobile(_device.ownerMobile);
                    setFormDeviceMobile(_device.mobile);
                    setFormDeviceCarName(_device.carName);
                    setFormDeviceType(_device.deviceType);

                    setValue('mobile', _device.mobile);
                    setValue('ownerMobile', "");
                    setValue('carName', _device.carName);
                    setValue('deviceType', _device.deviceType);
                }
            );
        }
    };

    // form
    const { register, resetField, formState: { errors }, handleSubmit , setValue} = useForm<FormDeviceInput>({defaultValues: { mobile: '', ownerMobile: '', carName: '', deviceType: '' }})
    const onSubmit: SubmitHandler<FormDeviceInput> = async (formData:FormDeviceInput) =>
    {
        const da = dialogAlert.current;
        let errorMessage:string = "";
        setIsSaving(true);

        // save device in db
        let tmpFormData;
        if(deviceId != null)
            tmpFormData = {id: device.id, mobile: helpers.initMobile(formData.mobile), carName: formData.carName, deviceType: formData.deviceType, selected: device.selected};
        else
            tmpFormData = {mobile: helpers.initMobile(formData.mobile), carName: formData.carName, deviceType: formData.deviceType, selected: redirectToHome ? 1 : 0};

        indexedDb.putValue(tableNames.Devices, tmpFormData).then
        (
            status =>
            {
                if(status)
                {
                    setDialogAlertTitle("");
                    setDialogAlertDes("اطلاعات با موفقیت ثبت شده است.");
                    setDialogAlertType("success");
                    da.show();

                    // reset form
                    if(deviceId == null && !redirectToHome)
                    {
                        setFormDeviceOwnerMobile('');
                        setFormDeviceMobile('');
                        setFormDeviceCarName('');
                        setFormDeviceType('');

                        resetField('ownerMobile');
                        resetField('mobile');
                        resetField('carName');
                        resetField('deviceType');
                    }

                    // redirect to home
                    if(redirectToHome)
                    {
                        indexedDb.putValue(tableNames.CentralNumber, {mobile: helpers.initMobile(formData.ownerMobile)}).then
                        (
                            r =>
                            {
                                indexedDb.putValue(tableNames.AdminNumber, {mobile: helpers.initMobile(formData.ownerMobile)}).then
                                (
                                    r =>
                                    {
                                        //navigate("/home");
                                        handleDialogAdminCentralOpen();
                                    }
                                );
                            }
                        );
                    }
                }
                else
                {
                    errorMessage = "مشکلی در ثبت اطلاعات پیش آمده است.";
                }
            }
        ).catch
        (
            function(error)
            {
                errorMessage = error;
            }
        ).finally
        (
            function()
            {
                setIsSaving(false);

                if(errorMessage !== "")
                {
                    setDialogAlertTitle("");
                    setDialogAlertDes(errorMessage);
                    setDialogAlertType("error");
                    da.show();
                }
            }
        );
    }

    // check devices from db
    const checkExistDevice = async () =>
    {
        const devices = await indexedDb.getAllValue(tableNames.Devices);
        return (devices.length > 0);
    };

    // admin central
    const handleDialogAdminCentralOpen = () =>
    {
        setDialogAdminCentralIsOpen(true);
    };

    const handleDialogAdminCentralClose: DialogProps["onClose"] = (event, reason) =>
    {
        if(reason && reason === "backdropClick") return;

        setDialogAdminCentralIsOpen(false);
    };

    const handleDialogAdminCentral = (type: number) =>
    {
        let smsCode = "";
        let returnToHome = false;

        switch (formDeviceType)
        {
            case "Concox":
            {
                smsCode = (type === 1) ?
                    (smsDevice.Concox.deviceAdmin.code.add).replace('Mobile1', helpers.initMobile(formDeviceOwnerMobile)).replace('Mobile2', '').replace('Mobile3', '')
                    :
                    (smsDevice.Concox.centralNumber.code.add).replace('Mobile', helpers.initMobile(formDeviceOwnerMobile)).replace('Number', '');

                if(type === 1) flagAdminClicked = true; else flagCentralClicked = true;
                returnToHome = flagAdminClicked && flagCentralClicked;

                break;
            }

            case "Coban":
            {
                smsCode = (smsDevice.Coban.deviceAdmin.code.add).replace('Mobile1', helpers.initMobile(formDeviceOwnerMobile));
                returnToHome = true;
                break;
            }
        }

        helpers.sendSms(helpers.initMobile(formDeviceMobile), smsCode);

        if(returnToHome)
        {
            navigate("/home");
        }
    };

    //--------------------
    return (
        <Template pageClass="page-device-add-edit" pageTitle={deviceId != null ? "ویرایش دستگاه جدید" : "درج دستگاه جدید"} showHeader={showHeader} showFooter={showFooter}>
            {/* --- form --- */}
            <form className="form" onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off">
                {redirectToHome ? <TextField label="شماره موبایل" id="formDeviceOwnerMobile" variant="filled" autoComplete="new-owner-mobile" dir="ltr" {...register("ownerMobile", {required: redirectToHome})} error={errors.mobile?.type === "required"} value={formDeviceOwnerMobile} onChange={(e) => setFormDeviceOwnerMobile(e.target.value)} /> : null}
                <TextField label="شماره سیم کارت" id="formDeviceMobile" variant="filled" autoComplete="new-mobile" dir="ltr" {...register("mobile", {required: true})} error={errors.mobile?.type === "required"} value={formDeviceMobile} onChange={(e) => setFormDeviceMobile(e.target.value)} />
                <TextField label="نام خودرو" id="formDeviceCarName" variant="filled" autoComplete="new-car-name" dir="rtl" {...register("carName", {required: true})} error={errors.carName?.type === "required"} value={formDeviceCarName} onChange={(e) => setFormDeviceCarName(e.target.value)} />

                <FormControl variant="filled" error={errors.deviceType?.type === "required"}>
                    <InputLabel id="formDeviceTypeLabel">نوع دستگاه</InputLabel>
                    <Select labelId="formDeviceTypeLabel" id="formDeviceType" {...register("deviceType", {required: true})} value={formDeviceType} onChange={(e) => setFormDeviceType(e.target.value)}>
                        <MenuItem value="Concox">Concox</MenuItem>
                        <MenuItem value="Coban">Coban</MenuItem>
                    </Select>
                </FormControl>

                <LoadingButton type="submit" fullWidth={true} size="large" variant="contained" color="success" loading={isSaving} disabled={isSaving}>ثبت</LoadingButton>
            </form>

            {/* modal admin central */}
            <Dialog fullWidth={true} open={dialogAdminCentralIsOpen} onClose={handleDialogAdminCentralClose} disableEscapeKeyDown={true}>
                {
                    formDeviceType === "Concox" ?
                        <DialogTitle>تنظیم ادمین و شماره مرکزی دستگاه</DialogTitle>
                        :
                        <DialogTitle>تنظیم ادمین دستگاه</DialogTitle>
                }

                <DialogActions>
                    <Button size="medium" variant="contained" color="primary" onClick={() => handleDialogAdminCentral(1)}>تنظیم ادمین</Button>
                    {formDeviceType === "Concox" ? <Button size="medium" variant="contained" color="secondary" onClick={() => handleDialogAdminCentral(2)}>تنظیم شماره مرکزی</Button> : null}
                </DialogActions>
            </Dialog>

            {/* --- --- --- */}
            <DialogAlert ref={dialogAlert} title={dialogAlertTitle} des={dialogAlertDes} type={dialogAlertType} isClose={true} />
        </Template>
    );
}

export default Add;
