import React, { forwardRef, useState, useImperativeHandle } from 'react';
import Dialog from "@mui/material/Dialog/Dialog";
import DialogContent from "@mui/material/DialogContent/DialogContent";
import {Button, DialogActions, DialogTitle, TextField} from "@mui/material";
import {SubmitHandler, useForm} from "react-hook-form";
import helpers from "../../utils/helpers";

//**********************************************************

interface FormAlarmSos
{
    mobile: string
}

//**********************************************************

const Index = forwardRef((props:{mobileNumber: string, smsCode: string}, ref) =>
    {
        const [isOpen, setIsOpen] = useState(false);
        
        useImperativeHandle
        (
            ref, () =>
                (
                    {
                        show: () => { setIsOpen(true); },
                        hide: () => { setIsOpen(false); },
                    }
                )
        );
        
        const handleClose = () =>
        {
            setIsOpen(false);
        };
        
        // form
        const { register, formState: { errors }, handleSubmit } = useForm<FormAlarmSos>()
        const onSubmitFormAlarmSos: SubmitHandler<FormAlarmSos> = (formData:FormAlarmSos) =>
        {
            if(formData.mobile !== "")
            {
                // window.open(props.smsCode.replace('ReceiverMobile', formData.mobile.toString()));
                helpers.sendSms(props.mobileNumber, props.smsCode.replace('ReceiverMobile', formData.mobile.toString()));
                handleClose();
            }
        }
        
        //--------------------
        return(
            <Dialog open={isOpen} onClose={handleClose} PaperProps={{component: 'form', onSubmit: handleSubmit(onSubmitFormAlarmSos)}}>
                <DialogTitle>هشدار SOS</DialogTitle>
                
                <DialogContent>
                    <TextField type="number" className="t2" label="شماره موبایل شخص را وارد نمایید" id="FormAlarmSosMobile" variant="filled" dir="ltr" {...register("mobile", {required: true})} error={errors.mobile?.type === "required"} />
                </DialogContent>
                
                <DialogActions>
                    <Button size="medium" variant="outlined" onClick={handleClose}>لغو</Button>
                    <Button type="submit" size="medium" variant="contained" color="success">ارسال</Button>
                </DialogActions>
            </Dialog>
        );
    }
);

export default Index;
