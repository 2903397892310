import React, { forwardRef, useState, useImperativeHandle } from 'react';
import Dialog from "@mui/material/Dialog/Dialog";
import DialogContent from "@mui/material/DialogContent/DialogContent";
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import "./index.scss";
import iconSuccess from "../../assets/images/icon-success.gif";
import iconWarning from "../../assets/images/icon-warning.gif";
import type { DialogProps } from "@mui/material";

//**********************************************************

const Index = forwardRef((props:{title: string, des: string, type: string, isClose: boolean}, ref) =>
    {
        const [isOpen, setIsOpen] = useState(false);

        useImperativeHandle
        (
            ref, () =>
                (
                    {
                        show: () => { setIsOpen(true); },
                        hide: () => { setIsOpen(false); },
                    }
                )
        );

        const handleClose: DialogProps["onClose"] = (event, reason) =>
        {
            if(!props.isClose && reason && reason === "backdropClick") return;

            setIsOpen(false);
        };

        //--------------------
        return(
            <Dialog className="dialog-alert" open={isOpen} onClose={handleClose} fullWidth={true} maxWidth='xs' disableEscapeKeyDown={true}>
                {props.isClose ? <IconButton className="btn-close" aria-label="close" onClick={() => setIsOpen(false)}><CloseIcon /></IconButton> : null}
                <DialogContent className="dialog-content">
                    <img src={props.type === "success" ? iconSuccess : iconWarning}  alt="" />
                    {props.title !== "" ? <div className="title">{props.title}</div> : null}
                    {props.des !== "" ? <div className="des">{props.des}</div> : null}
                </DialogContent>
            </Dialog>
        );
    }
);

export default Index;
