import React from 'react';
import {Navigate, Outlet} from 'react-router-dom';
import {useAppSelector} from "../states/hooks";

//**********************************************************

function RouterPrivate()
{
    const userData = useAppSelector(state => state.userState);

    return <>{ (userData.email !== "" && userData.password !== "") ? <Outlet /> : <Navigate to="/login" /> }</>;
}

export default RouterPrivate;
