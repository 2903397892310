import React, {useEffect, useState} from 'react';
import Template from "../../layouts/Template";
import './index.scss';
import {useAppSelector} from "../../states/hooks";
import {Link, useNavigate} from "react-router-dom";
import CurrentDevice from "../../components/CurrentDevice";
import IndexedDb from "../../database/indexedDb";
import {tableDevice, tableNames} from "../../database/interfaceDb";
import smsDevice from "../../utils/smsDevice";
import imageLogo from "../../assets/images/cartracker-logo-2.png";
import Tooltip from '@mui/material/Tooltip';
import LockTwoToneIcon from '@mui/icons-material/LockTwoTone';
import LockOpenTwoToneIcon from '@mui/icons-material/LockOpenTwoTone';
import PhoneInTalkTwoToneIcon from '@mui/icons-material/PhoneInTalkTwoTone';
import LocationOnTwoToneIcon from '@mui/icons-material/LocationOnTwoTone';
import MicTwoToneIcon from '@mui/icons-material/MicTwoTone';
import MicOffTwoToneIcon from '@mui/icons-material/MicOffTwoTone';
import {Button, MenuItem} from "@mui/material";
import AddCircleTwoToneIcon from '@mui/icons-material/AddCircleTwoTone';
import {deviceState} from "../../states/deviceState";

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import IconButton from "@mui/material/IconButton";
import BorderColorTwoToneIcon from '@mui/icons-material/BorderColorTwoTone';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import DialogAlert from "../../components/DialogAlert";

//**********************************************************

let indexedDb: IndexedDb;

//**********************************************************

function Index()
{
    const navigate = useNavigate();
    const currentDeviceState = useAppSelector(state => state.deviceState);
    const [devices, setDevices] = useState<tableDevice[]>([]);

    const dialogAlert = React.createRef<any>();
    const [dialogAlertType, setDialogAlertType] = useState<string>('');
    const [dialogAlertTitle, setDialogAlertTitle] = useState<string>('');
    const [dialogAlertDes, setDialogAlertDes] = useState<string>('');

    // open db
    const initDB = () =>
    {
        indexedDb = new IndexedDb();
        indexedDb.open().then(r => { getDevices(); });
    };
    useEffect(() => { initDB(); }, []);

    // get devices from db
    const getDevices = () =>
    {
        indexedDb.getAllValue(tableNames.Devices).then
        (
            data =>
            {
                setDevices(data);
            }
        );
    };

    const handleDeviceDelete = (item:tableDevice) =>
    {
        const da = dialogAlert.current;

        //
        if(item.selected !== 1)
        {
            indexedDb.deleteValue(tableNames.Devices, item.id).then(r => { getDevices(); });
        }
        else
        {
            setDialogAlertTitle("");
            setDialogAlertDes("امکان حذف دستگاه پیش فرض را ندارید.");
            setDialogAlertType("error");
            da.show();
        }
    };

    //--------------------
    return (
        <Template pageClass="page-devices" pageTitle="دستگاه ها">
            <Button size="small" color="info" variant="contained" component={Link} to="/device/add" className="btn-device-add"><AddCircleTwoToneIcon className="icon" />درج دستگاه جدید</Button>

            <TableContainer component={Paper} className="devices-table">
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell align="left">نام خودرو</TableCell>
                            <TableCell align="center">شماره سیم کارت</TableCell>
                            {/*<TableCell align="center">نوع دستگاه</TableCell>*/}
                            <TableCell align="right"></TableCell>
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {
                            devices != null && devices.length > 0 ?
                                devices.map
                                (
                                    (item, index) =>
                                    {
                                        return(
                                            <TableRow key={item.id}>
                                                <TableCell align="left">{item.carName}</TableCell>
                                                <TableCell align="center">{item.mobile}</TableCell>
                                                {/*<TableCell align="center">{item.deviceType}</TableCell>*/}
                                                <TableCell align="right" className="col-btns">
                                                    <IconButton component={Link} to={"/device/edit/" + item.id}>
                                                        <BorderColorTwoToneIcon />
                                                    </IconButton>

                                                    <IconButton onClick={e => handleDeviceDelete(item)}>
                                                        <DeleteTwoToneIcon />
                                                    </IconButton>
                                                </TableCell>
                                            </TableRow>
                                        )
                                    }
                                )
                                :
                                null
                        }
                    </TableBody>
                </Table>
            </TableContainer>

            {/* --- --- --- */}
            <DialogAlert ref={dialogAlert} title={dialogAlertTitle} des={dialogAlertDes} type={dialogAlertType} isClose={true} />
        </Template>
    );
}

export default Index;
