
export enum tableNames
{
    Servers = 'servers',
    Users = 'users',
    Devices = 'devices',
    CentralNumber = 'central-number',
    AdminNumber = 'admin-number',
}

export interface tableServer
{
    id: number;
    url: string;
}

export interface tableUser
{
    id: number;
    email: string;
    password: string;
    data: string;
}

export interface tableDevice
{
    id: number;
    mobile: string;
    carName: string;
    deviceType: string;
    selected: number;
}

export interface tableCentralNumber
{
    id: number;
    mobile: string;
}

export interface tableAdminNumber
{
    id: number;
    mobile: string;
}
