import React from 'react';
import Template from "../../layouts/Template";
import "./resetDevice.scss";
import {Button, Link} from "@mui/material";
import {useAppSelector} from "../../states/hooks";
import smsDevice from "../../utils/smsDevice";
import CurrentDevice from "../../components/CurrentDevice";

//**********************************************************

function ResetDevice()
{
    const currentDeviceState = useAppSelector(state => state.deviceState);


    //--------------------
    return (
        <Template pageClass="page-reset-device" pageTitle="راه اندازی مجدد دستگاه">
            <CurrentDevice />

            <div className="des-btns">
                {
                    {
                        'Concox': <>
                            <div className="des">در صورت اطمینان از راه اندازی مجدد دستگاه، دو دکمه زیر را به ترتیب مراحل بفشارید.</div>
                            <div className="btns-box">
                                <Button href={"sms:" + currentDeviceState.mobile + "?&body=" + smsDevice.Concox.resetDevice.code.step1} component={Link} size="large" variant="contained" color="error" className="btn">مرحله 1 <br /> راه اندازی مجدد</Button>
                                <Button href={"sms:" + currentDeviceState.mobile + "?&body=" + smsDevice.Concox.resetDevice.code.step2} component={Link} size="large" variant="contained" color="error" className="btn">مرحله 2 <br/> راه اندازی مجدد</Button>
                            </div>
                        </>,

                        'Coban': <>
                            <div className="des">در صورت اطمینان از راه اندازی مجدد دستگاه، دکمه زیر را بفشارید.</div>
                            <Button href={"sms:" + currentDeviceState.mobile + "?&body=" + smsDevice.Coban.resetDevice.code} component={Link} fullWidth={true} size="large" variant="contained" color="error" className="btn">بله، راه اندازی مجدد را انجام بده</Button>
                        </>,
                    }
                        [currentDeviceState.deviceType]
                }
            </div>
        </Template>
    );
}

export default ResetDevice;
