import React, {useEffect, useState} from 'react';
import Template from "../../layouts/Template";
import "./centralNumber.scss";
import {Button, TextField} from "@mui/material";
import {useAppSelector} from "../../states/hooks";
import CurrentDevice from "../../components/CurrentDevice";
import DeleteTwoToneIcon from "@mui/icons-material/DeleteTwoTone";
import SendTwoToneIcon from '@mui/icons-material/SendTwoTone';
import smsDevice from "../../utils/smsDevice";
import helpers from "../../utils/helpers";
import IndexedDb from "../../database/indexedDb";
import {tableNames} from "../../database/interfaceDb";

//**********************************************************

let indexedDb: IndexedDb;

//**********************************************************

function CentralNumber()
{
    const currentDeviceState = useAppSelector(state => state.deviceState);

    const [centralNumberMobile1, setCentralNumberMobile1] = useState<string>('');
    const [centralNumberMobile2, setCentralNumberMobile2] = useState<string>('');
    const [centralNumberMobile3, setCentralNumberMobile3] = useState<string>('');

    // open db
    const initDB = () =>
    {
        indexedDb = new IndexedDb();
        indexedDb.open().then(r => {});
    };
    useEffect(() => { initDB(); }, []);

    // send sms
    const handleSendSms = async (type: string, fieldNumber: number) =>
    {
        let mobile = "";
        let smsCode = "";

        switch(type)
        {
            case "add": smsCode = smsDevice.Concox.centralNumber.code.add; break;
            case "remove": smsCode = smsDevice.Concox.centralNumber.code.remove; break;
        }

        switch(fieldNumber)
        {
            case 1: mobile = helpers.initMobile(centralNumberMobile1); break;
            case 2: mobile = helpers.initMobile(centralNumberMobile2); break;
            case 3: mobile = helpers.initMobile(centralNumberMobile3); break;
        }

        if(mobile !== "")
        {
            // save in db
            if(fieldNumber === 1)
            {
                await indexedDb.getAllValue(tableNames.CentralNumber).then
                (
                    async objCentralNumbers =>
                    {
                        let tmpFormData = (objCentralNumbers.length > 0) ? {id: objCentralNumbers[0].id, mobile: helpers.initMobile(mobile)} : {mobile: helpers.initMobile(mobile)};

                        await indexedDb.putValue(tableNames.CentralNumber, tmpFormData);
                    }
                );
            }

            //
            // window.open("sms:" + currentDeviceState.mobile + "?&body=" + smsCode.replace('Mobile', mobile).replace('Number', (fieldNumber > 1 ? fieldNumber : '').toString()));
            helpers.sendSms(currentDeviceState.mobile, smsCode.replace('Mobile', mobile).replace('Number', (fieldNumber > 1 ? fieldNumber : '').toString()));
        }
    }

    //--------------------
    return (
        <Template pageClass="page-central-number" pageTitle="شماره مرکزی">
            <CurrentDevice/>

            <div className="des">شماره های مرکزی برای دریافت هشدارهای؛ روشن شدن، باز شدن درب، ضربه و خاموش شدن خودرو می باشد.</div>

            <div className="form">
                <div className="input-box">
                    <TextField label="شماره 1" variant="filled" autoComplete="new-mobile-1" dir="ltr" value={centralNumberMobile1} onChange={(e) => setCentralNumberMobile1(e.target.value)}/>
                    <Button variant="contained" color="success" className="btn" onClick={() => handleSendSms("add", 1)}><SendTwoToneIcon className="icon"/></Button>
                    <Button variant="contained" color="error" className="btn" onClick={() => handleSendSms("remove", 1)}><DeleteTwoToneIcon className="icon"/></Button>
                </div>

                <div className="input-box">
                    <TextField label="شماره 2" variant="filled" autoComplete="new-mobile-2" dir="ltr" value={centralNumberMobile2} onChange={(e) => setCentralNumberMobile2(e.target.value)}/>
                    <Button variant="contained" color="success" className="btn" onClick={() => handleSendSms("add", 2)}><SendTwoToneIcon className="icon"/></Button>
                    <Button variant="contained" color="error" className="btn" onClick={() => handleSendSms("remove", 2)}><DeleteTwoToneIcon className="icon"/></Button>
                </div>

                <div className="input-box">
                    <TextField label="شماره 3" variant="filled" autoComplete="new-mobile-3" dir="ltr" value={centralNumberMobile3} onChange={(e) => setCentralNumberMobile3(e.target.value)}/>
                    <Button variant="contained" color="success" className="btn" onClick={() => handleSendSms("add", 3)}><SendTwoToneIcon className="icon"/></Button>
                    <Button variant="contained" color="error" className="btn" onClick={() => handleSendSms("remove", 3)}><DeleteTwoToneIcon className="icon"/></Button>
                </div>
            </div>
        </Template>);
}

export default CentralNumber;
