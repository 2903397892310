import React, {useEffect, useState} from 'react';
import {Button, Menu, MenuItem} from "@mui/material";
import IndexedDb from "../../database/indexedDb";
import {tableDevice, tableNames} from "../../database/interfaceDb";
import "./index.scss";
import {useAppDispatch, useAppSelector} from "../../states/hooks";
import {deviceState} from "../../states/deviceState";

//**********************************************************

let indexedDb: IndexedDb;

//**********************************************************
function Index()
{
    const dispatch = useAppDispatch();

    const [devices, setDevices] = useState<tableDevice[]>([]);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const openDeviceItems = Boolean(anchorEl);
    const currentDeviceState = useAppSelector(state => state.deviceState);

    // open db
    const initDB = () =>
    {
        indexedDb = new IndexedDb();
        indexedDb.open().then(r => { getDevices(); });
    };
    useEffect(() => { initDB(); }, []);

    // get devices from db
    const getDevices = () =>
    {
        indexedDb.getAllValue(tableNames.Devices).then
        (
            data =>
            {
                setDevices(data);

                // set default device
                for(let device of data)
                {
                    if(device.selected === 1) dispatch(deviceState.actions.save(device));
                }
            }
        );
    };

    // handle device items
    const handleOpenDeviceItems = (event: React.MouseEvent<HTMLElement>) =>
    {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseDeviceItems = () =>
    {
        setAnchorEl(null);
    };

    const handleDeviceChange = (item:tableDevice) =>
    {
        handleCloseDeviceItems();
        dispatch(deviceState.actions.save(item));

        // update selected column in tableDevice
        for(let device of devices)
        {
            device.selected = (device.id === item.id) ? 1 : 0;
            indexedDb.putValue(tableNames.Devices, device).then(r => {});
        }
    };

    //--------------------
    return (
        <>
            {
                devices != null && devices.length > 1 ?
                    <div className="current-device">
                        <span className="title">خودرو انتخاب شده:</span>
                        <span className="car-name">{currentDeviceState.carName}</span>
                        <div className="btn-change">
                            <Button className="btn-title" onClick={handleOpenDeviceItems}>(تغییر خودرو)</Button>
                            <Menu anchorEl={anchorEl} open={openDeviceItems} onClose={handleCloseDeviceItems} className="device-menu-items">
                                {
                                    devices.map
                                    (
                                        (item, index) =>
                                        {
                                            return <MenuItem className="item" onClick={() => handleDeviceChange(item)} key={index}>{item.carName}</MenuItem>
                                        }
                                    )
                                }
                            </Menu>
                        </div>
                    </div>
                    :
                    null
            }
        </>
    );
}

export default Index;
