import React, {useEffect, useState} from 'react';
import Template from "../../layouts/Template";
import IndexedDb from "../../database/indexedDb";
import {LoadingButton} from "@mui/lab";
import {tableNames} from "../../database/interfaceDb";
import DialogAlert from "../../components/DialogAlert";
import "./resetPassword.scss";

//**********************************************************

let indexedDb: IndexedDb;

//**********************************************************

function ResetPassword()
{
    const [isSaving, setIsSaving] = useState<boolean>(false);
    
    const dialogAlert = React.createRef<any>();
    const [dialogAlertType, setDialogAlertType] = useState<string>('');
    const [dialogAlertTitle, setDialogAlertTitle] = useState<string>('');
    const [dialogAlertDes, setDialogAlertDes] = useState<string>('');
    
    // open db
    const initDB = () =>
    {
        indexedDb = new IndexedDb();
        indexedDb.open().then(r => {});
    };
    useEffect(() => { initDB(); }, []);
    
    // remove user data from db
    const handleRemoveUser = async () =>
    {
        const da = dialogAlert.current;
        setIsSaving(true);
        
        const users = await indexedDb.getAllValue(tableNames.Users);
        if (users.length > 0)
        {
            for (const user of users)
            {
                await indexedDb.deleteValue(tableNames.Users, user.id);
            }
        }
        
        setDialogAlertTitle("");
        setDialogAlertDes("مشخصات ورود با موفقیت حذف شده اند.");
        setDialogAlertType("success");
        da.show();
        
        setIsSaving(false);
    };
    
    //--------------------
    return (
        <Template pageClass="page-reset-password" pageTitle="لغو ذخیره کلمه عبور">
            <div className="des">در صورت تایید برای ورود مجدد به نرم افزار باید نام کاربری و رمز عبور خود را وارد نمایید.</div>
            <LoadingButton fullWidth={true} size="large" variant="contained" color="error" loading={isSaving} disabled={isSaving} onClick={handleRemoveUser} className="btn-reset">لغو ذخیره کلمه عبور به نرم افزار</LoadingButton>
            
            {/* --- --- --- */}
            <DialogAlert ref={dialogAlert} title={dialogAlertTitle} des={dialogAlertDes} type={dialogAlertType} isClose={true} />
        </Template>
    );
}

export default ResetPassword;
