import React from 'react';
import Template from "../../layouts/Template";
import './index.scss';
import imageLogo from "../../assets/images/cartracker-logo-1.png";

//**********************************************************

function Index()
{
    //--------------------
    return (
        <Template pageClass="page-about" pageTitle="درباره ما">
            <img className="logo" src={imageLogo} alt=""/>
            <div className="title">نرم افزار ردیابی خودرو ایران جی پی اس </div>
            <div className="des">شرکت ایران جی پی اس ردیاب فعال در زمینه تجهیزات امنیتی از سال 1389 وارد کننده و تولید کننده انواع سیستم های ردیاب خودرو آهنربایی موتور سیکلت می باشد. برای مشاهده دیگر محصولات به سایت <a href="https://www.irgpstracker.com" target="_blank" className="link">irgpstracker.com</a> مراجعه کنید و برای مسائل مربوط به پشتیبانی به آدرس <a href="https://www.irgpstracker.com/support" target="_blank" className="link">irgpstracker.com/support</a> مراجعه کنید.</div>
            {/*<div className="support">پشتیبانی تلگرام و ایتا: <b>09128706267</b></div>*/}
            {/*<div className="link-box">وب سایت: <a href="https://www.irgpstracker.com/" target="_blank" className="link">irgpstracker.com</a></div>*/}
        </Template>
    );
}

export default Index;
