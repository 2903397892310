import React, {useState} from 'react';
import Template from "../../layouts/Template";
import './index.scss';
import {useAppSelector} from "../../states/hooks";
import CurrentDevice from "../../components/CurrentDevice";
import smsDevice from "../../utils/smsDevice";
import {Button, DialogActions, DialogTitle} from "@mui/material";
import Dialog from "@mui/material/Dialog/Dialog";
import DialogContent from "@mui/material/DialogContent/DialogContent";
import DialogAlarmSos from "../../components/DialogAlarmSos";
import DialogAlarmSpeed from "../../components/DialogAlarmSpeed";
import DialogAlarmDistance from "../../components/DialogAlarmDistance";
import helpers from "../../utils/helpers";

//**********************************************************

function Index()
{
    const currentDeviceState = useAppSelector(state => state.deviceState);
    
    const [dialogCallSmsTitle, setDialogCallSmsTitle] = useState('');
    const [dialogCallSmsCode, setDialogCallSmsCode] = useState(null);
    const [dialogCallSmsIsOpen, setDialogCallSmsIsOpen] = useState(false);
    
    const dialogAlarmSpeed = React.createRef<any>();
    const [dialogAlarmSpeedMobileNumber, setDialogAlarmSpeedMobileNumber] = useState<string>('');
    const [dialogAlarmSpeedCode, setDialogAlarmSpeedCode] = useState<string>('');

    const dialogAlarmSos = React.createRef<any>();
    const [dialogAlarmSosMobileNumber, setDialogAlarmSosMobileNumber] = useState<string>('');
    const [dialogAlarmSosCode, setDialogAlarmSosCode] = useState<string>('');

    const dialogAlarmMoving = React.createRef<any>();
    
    //
    const handleAlarmSpeed = (smsBody:string) =>
    {
        setDialogAlarmSpeedMobileNumber(currentDeviceState.mobile);
        setDialogAlarmSpeedCode(smsBody);
        dialogAlarmSpeed.current.show();
    }
    
    const handleAlarmSos = (smsBody:string) =>
    {
        setDialogAlarmSosMobileNumber(currentDeviceState.mobile);
        setDialogAlarmSosCode(smsBody);
        dialogAlarmSos.current.show();
    }
    
    const handleAlarmMoving = () =>
    {
        dialogAlarmMoving.current.show();
    }
    
    const handleAlarmMovingCallback = (distance:number) =>
    {
        let code =
            {
                on: smsDevice.Concox.alarmMoving.code.on.replace('Distance', distance.toString()),
                on2: smsDevice.Concox.alarmMoving.code.on2.replace('Distance', distance.toString())
            }
        
        handleDialogCallSmsOpen(smsDevice.Concox.alarmMoving.title, code);
    }
    
    // call sms
    const handleDialogCallSmsOpen = (title:string, smsCode:any) =>
    {
        setDialogCallSmsTitle(title);
        setDialogCallSmsCode(smsCode);
        setDialogCallSmsIsOpen(true);
    };
    
    const handleDialogCallSmsClose = () =>
    {
        setDialogCallSmsIsOpen(false);
    };
    
    const handleDialogCallSms = (type: number) =>
    {
        if(type === 1)
        {
            // @ts-ignore
            helpers.sendSms(currentDeviceState.mobile, dialogCallSmsCode.on);
            // @ts-ignore
            // window.open("sms:" + currentDeviceState.mobile + "?&body=" + dialogCallSmsCode.on);
        }
        else
        {
            // @ts-ignore
            helpers.sendSms(currentDeviceState.mobile, dialogCallSmsCode.on2);
            // @ts-ignore
            // window.open("sms:" + currentDeviceState.mobile + "?&body=" + dialogCallSmsCode.on2);
        }
        
        handleDialogCallSmsClose();
    };
    
    //--------------------
    return (
        <Template pageClass="page-sms" pageTitle="دستورات">
            <CurrentDevice />
            
            <div className="btn-codes">
                {
                    {
                        'Concox': <>
                            <div className="code-box">
                                <div className="btn-box">
                                    <span onClick={() => helpers.sendSms(currentDeviceState.mobile, smsDevice.Concox.turnOff.code.on)} className="btn-on">On</span>
                                    <span onClick={() => helpers.sendSms(currentDeviceState.mobile, smsDevice.Concox.turnOff.code.off)} className="btn-off">Off</span>
                                </div>
                                <div className="title">{smsDevice.Concox.turnOff.title}</div>
                            </div>
                            
                            <div className="code-box">
                                <div className="btn-box">
                                    <span onClick={() => handleAlarmSpeed(smsDevice.Concox.alarmSpeed.code.on)} className="btn-on">On</span>
                                    <span onClick={() => helpers.sendSms(currentDeviceState.mobile, smsDevice.Concox.alarmSpeed.code.off)} className="btn-off">Off</span>
                                </div>
                                <div className="title">{smsDevice.Concox.alarmSpeed.title}</div>
                            </div>
                            
                            <div className="code-box">
                                <div className="btn-box">
                                    <span onClick={() => helpers.sendSms(currentDeviceState.mobile, smsDevice.Concox.alarmDisconnectBattery.code.on)} className="btn-on">On</span>
                                    <span onClick={() => helpers.sendSms(currentDeviceState.mobile, smsDevice.Concox.alarmDisconnectBattery.code.off)} className="btn-off">Off</span>
                                </div>
                                <div className="title">{smsDevice.Concox.alarmDisconnectBattery.title}</div>
                            </div>
                            
                            <div className="code-box">
                                <div className="btn-box">
                                    <span onClick={() => handleDialogCallSmsOpen(smsDevice.Concox.alarmDoor.title, smsDevice.Concox.alarmDoor.code)} className="btn-on">On</span>
                                    <span onClick={() => helpers.sendSms(currentDeviceState.mobile, smsDevice.Concox.alarmDoor.code.off)} className="btn-off">Off</span>
                                </div>
                                <div className="title">{smsDevice.Concox.alarmDoor.title}</div>
                            </div>
                            
                            <div className="code-box">
                                <div className="btn-box">
                                    <span onClick={() => handleDialogCallSmsOpen(smsDevice.Concox.alarmImpact.title, smsDevice.Concox.alarmImpact.code)} className="btn-on">On</span>
                                    <span onClick={() => helpers.sendSms(currentDeviceState.mobile, smsDevice.Concox.alarmImpact.code.off)} className="btn-off">Off</span>
                                </div>
                                <div className="title">{smsDevice.Concox.alarmImpact.title}</div>
                            </div>
                            
                            <div className="code-box">
                                <div className="btn-box">
                                    <span onClick={handleAlarmMoving} className="btn-on">On</span>
                                    <span onClick={() => helpers.sendSms(currentDeviceState.mobile, smsDevice.Concox.alarmMoving.code.off)} className="btn-off">Off</span>
                                </div>
                                <div className="title">{smsDevice.Concox.alarmMoving.title}</div>
                            </div>
                            
                            <div className="code-box">
                                <div className="btn-box">
                                    <span onClick={() => handleDialogCallSmsOpen(smsDevice.Concox.alarmTurnOn.title, smsDevice.Concox.alarmTurnOn.code)} className="btn-on">On</span>
                                    <span onClick={() => helpers.sendSms(currentDeviceState.mobile, smsDevice.Concox.alarmTurnOn.code.off)} className="btn-off">Off</span>
                                </div>
                                <div className="title">{smsDevice.Concox.alarmTurnOn.title}</div>
                            </div>
                            
                            <div className="code-box">
                                <div className="btn-box">
                                    <span onClick={() => handleDialogCallSmsOpen(smsDevice.Concox.alarmTurnOff.title, smsDevice.Concox.alarmTurnOff.code)} className="btn-on">On</span>
                                    <span onClick={() => helpers.sendSms(currentDeviceState.mobile, smsDevice.Concox.alarmTurnOff.code.off)} className="btn-off">Off</span>
                                </div>
                                <div className="title">{smsDevice.Concox.alarmTurnOff.title}</div>
                            </div>
                        </>,
                        
                        'Coban': <>
                            <div className="code-box">
                                <div className="btn-box">
                                    <span onClick={() => helpers.sendSms(currentDeviceState.mobile, smsDevice.Coban.turnOff.code.on)} className="btn-on">On</span>
                                    <span onClick={() => helpers.sendSms(currentDeviceState.mobile, smsDevice.Coban.turnOff.code.off)} className="btn-off">Off</span>
                                </div>
                                <div className="title">{smsDevice.Coban.turnOff.title}</div>
                            </div>
                            
                            <div className="code-box">
                                <div className="btn-box">
                                    <span onClick={() => handleAlarmSpeed(smsDevice.Coban.alarmSpeed.code.on)} className="btn-on">On</span>
                                    <span onClick={() => helpers.sendSms(currentDeviceState.mobile, smsDevice.Coban.alarmSpeed.code.off)} className="btn-off">Off</span>
                                </div>
                                <div className="title">{smsDevice.Coban.alarmSpeed.title}</div>
                            </div>
                            
                            <div className="code-box">
                                <div className="btn-box">
                                    <span onClick={() => helpers.sendSms(currentDeviceState.mobile, smsDevice.Coban.alarmMoving.code.on)} className="btn-on">On</span>
                                    <span onClick={() => helpers.sendSms(currentDeviceState.mobile, smsDevice.Coban.alarmMoving.code.off)} className="btn-off">Off</span>
                                </div>
                                <div className="title">{smsDevice.Coban.alarmMoving.title}</div>
                            </div>
                            
                            <div className="code-box">
                                <div className="btn-box">
                                    <span onClick={() => helpers.sendSms(currentDeviceState.mobile, smsDevice.Coban.alarmTurnOn.code.on)} className="btn-on">On</span>
                                    <span onClick={() => helpers.sendSms(currentDeviceState.mobile, smsDevice.Coban.alarmTurnOn.code.off)} className="btn-off">Off</span>
                                </div>
                                <div className="title">{smsDevice.Coban.alarmTurnOn.title}</div>
                            </div>
                            
                            <div className="code-box">
                                <div className="btn-box">
                                    <span onClick={() => handleAlarmSos(smsDevice.Coban.alarmSOS.code)} className="btn-single">SOS</span>
                                </div>
                                <div className="title">{smsDevice.Coban.alarmSOS.title}</div>
                            </div>
                        </>,
                    }
                        [currentDeviceState.deviceType]
                }
            </div>
            
            {/* modal get max speed */}
            <DialogAlarmSpeed ref={dialogAlarmSpeed} mobileNumber={dialogAlarmSpeedMobileNumber} smsCode={dialogAlarmSpeedCode} />
            
            {/* modal get sos mobile */}
            <DialogAlarmSos ref={dialogAlarmSos} mobileNumber={dialogAlarmSosMobileNumber} smsCode={dialogAlarmSosCode} />
            
            {/* modal get distance */}
            <DialogAlarmDistance ref={dialogAlarmMoving} callback={handleAlarmMovingCallback} />
            
            {/* modal call sms */}
            <Dialog open={dialogCallSmsIsOpen} onClose={handleDialogCallSmsClose}>
                <DialogTitle>{dialogCallSmsTitle}</DialogTitle>
                
                <DialogContent>یک گزینه را انتخاب نمایید.</DialogContent>
                
                <DialogActions>
                    <Button size="medium" variant="outlined" onClick={handleDialogCallSmsClose}>لغو</Button>
                    <Button size="medium" variant="contained" color="primary" onClick={() => handleDialogCallSms(2)}>پیامک و تماس</Button>
                    <Button size="medium" variant="contained" color="secondary" onClick={() => handleDialogCallSms(1)}>پیامک</Button>
                </DialogActions>
            </Dialog>
        </Template>
    );
}

export default Index;
