import React, {useEffect, useState} from 'react';
import {useNavigate} from "react-router-dom";
import Template from "../../layouts/Template";
import DialogAlert from "../../components/DialogAlert";
import imageLogo from "../../assets/images/cartracker-logo-1.png";
import './index.scss';
import IndexedDb from "../../database/indexedDb";
import {tableNames} from "../../database/interfaceDb";

//**********************************************************

function Index()
{
    const navigate = useNavigate();

    const dialogAlert = React.createRef<any>();
    const [dialogAlertType, setDialogAlertType] = useState<string>('');
    const [dialogAlertTitle, setDialogAlertTitle] = useState<string>('');
    const [dialogAlertDes, setDialogAlertDes] = useState<string>('');

    // create indexedDb object
    let indexedDb: IndexedDb;
    const initDB = async () =>
    {
        indexedDb = new IndexedDb();
        await indexedDb.createObjectStore().then
        (
            r =>
            {
                if(r)
                {
                    // check server selected
                    checkTableServer().then(hasServer =>
                    {
                        setTimeout(() => { navigate(hasServer ? "/login" : "/server"); }, 2000);
                    });
                }
                else
                {
                    setDialogAlertTitle("");
                    setDialogAlertDes("مشکلی در آماده سازی نرم افزار پیش آمده است.");
                    setDialogAlertType("error");
                    dialogAlert.current.show();
                }
            }
        );
    };
    useEffect(() => { initDB(); }, []);

    // check server selected
    const checkTableServer = async () =>
    {
        const servers = await indexedDb.getAllValue(tableNames.Servers);
        return (servers.length > 0);
    };

    //--------------------
    return (
        <Template pageClass="page-splash" showHeader={false} showFooter={false}>
            <img className="logo" src={imageLogo} alt=""/>
            <div className="title">نرم افزار ردیابی خودرو ایران جی پی اس </div>

            <DialogAlert ref={dialogAlert} title={dialogAlertTitle} des={dialogAlertDes} type={dialogAlertType} isClose={false} />
        </Template>
    );
}

export default Index;
