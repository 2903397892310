import Router from "./routes/Router";
import "./assets/styles/main.css";
import {useEffect, useState} from "react";
import {userState} from "./states/userState";
import {useAppDispatch} from "./states/hooks";

//-----------------------------------------------
function App()
{
    const [isReady, setIsReady] = useState<boolean>(false);
    const dispatch = useAppDispatch();

    //
    useEffect(() =>
    {
        // call user data
        handleUpdateUser().then(r => setIsReady(true));
    }, []);

    const handleUpdateUser = async () =>
    {
        dispatch(userState.actions.get());
    }

    //--------------------

    return isReady ? <Router /> : null;
}

export default App;