import React, { forwardRef, useState, useImperativeHandle } from 'react';
import Dialog from "@mui/material/Dialog/Dialog";
import DialogContent from "@mui/material/DialogContent/DialogContent";
import {Button, DialogActions, DialogTitle, TextField} from "@mui/material";
import {SubmitHandler, useForm} from "react-hook-form";

//**********************************************************

interface FormAlarmDistance
{
    maxDistance: number
}

//**********************************************************

const Index = forwardRef((props:{callback: any}, ref) =>
    {
        const [isOpen, setIsOpen] = useState(false);
        
        useImperativeHandle
        (
            ref, () =>
                (
                    {
                        show: () => { setIsOpen(true); },
                        hide: () => { setIsOpen(false); },
                    }
                )
        );
        
        const handleClose = () =>
        {
            setIsOpen(false);
        };
        
        // form
        const { register, formState: { errors }, handleSubmit } = useForm<FormAlarmDistance>()
        const onSubmitFormAlarmDistance: SubmitHandler<FormAlarmDistance> = (formData:FormAlarmDistance) =>
        {
            if(formData.maxDistance > 0)
            {
                props.callback(formData.maxDistance);
                handleClose();
            }
        }
        
        //--------------------
        return(
            <Dialog open={isOpen} onClose={handleClose} PaperProps={{component: 'form', onSubmit: handleSubmit(onSubmitFormAlarmDistance)}}>
                <DialogTitle>هشدار حرکت</DialogTitle>
                
                <DialogContent>
                    <TextField type="number" className="t2" label="مسافت بر حسب متر را وارد نمایید" id="FormAlarmDistanceMaxDistance" variant="filled" dir="ltr" {...register("maxDistance", {required: true})} error={errors.maxDistance?.type === "required"} />
                </DialogContent>
                
                <DialogActions>
                    <Button size="medium" variant="outlined" onClick={handleClose}>لغو</Button>
                    <Button type="submit" size="medium" variant="contained" color="success">ارسال</Button>
                </DialogActions>
            </Dialog>
        );
    }
);

export default Index;
