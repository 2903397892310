import React, {useEffect, useState} from 'react';
import Template from "../../layouts/Template";
import './index.scss';
import {useAppSelector} from "../../states/hooks";
import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import CurrentDevice from "../../components/CurrentDevice";
import IndexedDb from "../../database/indexedDb";
import {tableNames} from "../../database/interfaceDb";
import smsDevice from "../../utils/smsDevice";
import imageLogo from "../../assets/images/cartracker-logo-2.png";
import Tooltip from '@mui/material/Tooltip';
import LockTwoToneIcon from '@mui/icons-material/LockTwoTone';
import LockOpenTwoToneIcon from '@mui/icons-material/LockOpenTwoTone';
import PhoneInTalkTwoToneIcon from '@mui/icons-material/PhoneInTalkTwoTone';
import LocationOnTwoToneIcon from '@mui/icons-material/LocationOnTwoTone';
import MicTwoToneIcon from '@mui/icons-material/MicTwoTone';
import MicOffTwoToneIcon from '@mui/icons-material/MicOffTwoTone';
import helpers from "../../utils/helpers";
import Dialog from "@mui/material/Dialog/Dialog";
import DialogContent from "@mui/material/DialogContent/DialogContent";
import {Button, Chip, DialogActions, Link, Snackbar, SnackbarContent} from "@mui/material";
import WifiOffTwoToneIcon from '@mui/icons-material/WifiOffTwoTone';

//**********************************************************

let indexedDb: IndexedDb;

//**********************************************************

function Index()
{
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const currentDeviceState = useAppSelector(state => state.deviceState);

    const [dialogRenewalIsOpen, setDialogRenewalIsOpen] = useState<boolean>(false);
    const [isOffline, setIsOffline] = useState(false);
    const [openIsOffline, setOpenIsOffline] = React.useState(false);

    // open db
    const initDB = () =>
    {
        indexedDb = new IndexedDb();
        indexedDb.open().then(r => { checkExistDevice(); });
    };
    useEffect(() => { initDB(); }, []);

    //
    useEffect
    (
        () =>
        {
            // renewal
            if(sessionStorage.getItem(String(process.env.REACT_APP_Renewal)) === "1")
            {
                setDialogRenewalIsOpen(true);
            }
            sessionStorage.removeItem(String(process.env.REACT_APP_Renewal));

            // is offline
            setIsOffline(sessionStorage.getItem(String(process.env.REACT_APP_Offline)) === "1");

            handleAlertOfflineShow();
        },
        []
    );

    const handleDialogRenewalClose = () =>
    {
        setDialogRenewalIsOpen(false);
    };

    // check devices from db
    const checkExistDevice = async () =>
    {
        const devices = await indexedDb.getAllValue(tableNames.Devices);
        if(devices.length === 0)
        {
            // redirect to home
            navigate("/device/add");
        }
    };

    //
    const handleConcoxActiveSystem = () =>
    {
        indexedDb.getAllValue(tableNames.CentralNumber).then
        (
            objCentralNumbers =>
            {
                if(objCentralNumbers.length > 0)
                {
                    let code = smsDevice.Concox.activeSystem.code.replace('Mobile', objCentralNumbers[0].mobile);

                    helpers.sendSms(currentDeviceState.mobile, code);
                }
            }
        );
    }

    // alert offline
    const handleAlertOfflineShow = () =>
    {
        setOpenIsOffline(true);
    };

    const handleAlertOfflineHide = (event: React.SyntheticEvent | Event, reason?: string) =>
    {
        setOpenIsOffline(false);
    };

    //--------------------
    return (
        <Template pageClass="page-home" pageTitle="خانه" showBtnBack={false}>
            <CurrentDevice />

            {isOffline ? <Chip className="label-offline" color="error" icon={<WifiOffTwoToneIcon />} label="آفلاین" /> : null}
            {/*{isOffline ? <WifiOffTwoToneIcon color="error" className="label-offline" /> : null}*/}
            {
                isOffline ?
                    <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open={openIsOffline} autoHideDuration={2000} onClose={handleAlertOfflineHide}>
                        <SnackbarContent style={{backgroundColor: '#d32f2f'}} message="شما بصورت آفلاین وارد شده اید." />
                    </Snackbar>
                    :
                    null
            }

            <div className="remote">
                <div className="btn-codes">
                    {
                        {
                            'Concox':
                                <>
                                    <span onClick={() => handleConcoxActiveSystem()} className="btn"><Tooltip title={smsDevice.Concox.activeSystem.title} placement="top"><LockTwoToneIcon className="icon" /></Tooltip></span>
                                    <span onClick={() => helpers.sendSms(currentDeviceState.mobile, smsDevice.Concox.inactiveSystem.code)} className="btn"><Tooltip title={smsDevice.Concox.inactiveSystem.title} placement="top"><LockOpenTwoToneIcon className="icon" /></Tooltip></span>
                                    <span onClick={() => helpers.sendSms(currentDeviceState.mobile, smsDevice.Concox.currentLocation.code)} className="btn"><Tooltip title={smsDevice.Concox.currentLocation.title} placement="top"><LocationOnTwoToneIcon className="icon" /></Tooltip></span>
                                    <a href={"tel:" + currentDeviceState.mobile} className="btn"><Tooltip title="شنود صدای داخل خودرو" placement="top"><PhoneInTalkTwoToneIcon className="icon" /></Tooltip></a>
                                </>,
                            'Coban':
                                <>
                                    <span onClick={() => helpers.sendSms(currentDeviceState.mobile, smsDevice.Coban.activeSystem.code)} className="btn"><Tooltip title={smsDevice.Coban.activeSystem.title} placement="top"><LockTwoToneIcon className="icon" /></Tooltip></span>
                                    <span onClick={() => helpers.sendSms(currentDeviceState.mobile, smsDevice.Coban.inactiveSystem.code)} className="btn"><Tooltip title={smsDevice.Coban.inactiveSystem.title} placement="top"><LockOpenTwoToneIcon className="icon" /></Tooltip></span>
                                    <span onClick={() => helpers.sendSms(currentDeviceState.mobile, smsDevice.Coban.activeListen.code)} className="btn"><Tooltip title={smsDevice.Coban.activeListen.title} placement="top"><MicTwoToneIcon className="icon" /></Tooltip></span>
                                    <span onClick={() => helpers.sendSms(currentDeviceState.mobile, smsDevice.Coban.inactiveListen.code)} className="btn"><Tooltip title={smsDevice.Coban.inactiveListen.title} placement="top"><MicOffTwoToneIcon className="icon" /></Tooltip></span>
                                </>,
                        }
                            [currentDeviceState.deviceType]
                    }
                </div>
                <a href="https://irgpstracker.com/" target="_blank" className="site-link" rel="noreferrer"><img src={imageLogo} className="logo"  alt="" /></a>
            </div>

            {/* modal renewal */}
            <Dialog fullWidth={true} open={dialogRenewalIsOpen} onClose={handleDialogRenewalClose}>
                <DialogContent>اعتبار حساب کاربری شما به زودی تمام می شود. برای تمدید هم اکنون اقدام نمایید.</DialogContent>

                <DialogActions>
                    <Button size="medium" variant="outlined" onClick={handleDialogRenewalClose}>لغو</Button>
                    <Button size="medium" variant="contained" color="secondary" onClick={handleDialogRenewalClose} component={Link} href="https://Irangps.net" target="_blank">تمدید اکانت</Button>
                </DialogActions>
            </Dialog>
        </Template>
    );
}

export default Index;
