import { IDBPDatabase, openDB } from 'idb';
import {tableNames} from "./interfaceDb";

//**********************************************************
class IndexedDb
{
    private database: string = 'DBTaksaCar';
    private version: number = 101;
    private db: any;

    public async open()
    {
        try
        {
            this.db = await openDB(this.database, this.version);
            return true;
        }
        catch(error)
        {
            return false;
        }
    }

    public async createObjectStore()
    {
        try
        {
            this.db = await openDB
            (
                this.database,
                this.version,
                {
                    upgrade(db: IDBPDatabase)
                    {
                        for(const tableName of Object.values(tableNames))
                        {
                            if(db.objectStoreNames.contains(tableName)) continue;

                            db.createObjectStore(tableName, { autoIncrement: true, keyPath: 'id' });
                        }
                    },
                }
            );

            return true;
        }
        catch(error)
        {
            return false;
        }
    }

    public async getValue(tableName: string, id: number)
    {
        const tx = this.db.transaction(tableName, 'readonly');
        const store = tx.objectStore(tableName);
        const result = await store.get(id);

        return result;
    }

    public async getAllValue(tableName: string)
    {
        const tx = this.db.transaction(tableName, 'readonly');
        const store = tx.objectStore(tableName);
        const result = await store.getAll();

        return result;
    }

    public async putValue(tableName: string, value: object)
    {
        const tx = this.db.transaction(tableName, 'readwrite');
        const store = tx.objectStore(tableName);
        const result = await store.put(value);

        return result;
    }

    public async putBulkValue(tableName: string, values: object[])
    {
        const tx = this.db.transaction(tableName, 'readwrite');
        const store = tx.objectStore(tableName);

        for (const value of values)
        {
            const result = await store.put(value);
        }

        return this.getAllValue(tableName);
    }

    public async deleteValue(tableName: string, id: number)
    {
        const tx = this.db.transaction(tableName, 'readwrite');
        const store = tx.objectStore(tableName);
        const result = await store.get(id);

        if(!result)
        {
            return result;
        }

        await store.delete(id);

        return id;
    }
}

export default IndexedDb;