import React from "react";
import Header from "../Header";
import Footer from "../Footer";

//**********************************************************

function Template({pageClass = "", pageTitle = "", showBtnBack = true, showHeader = true, showFooter = true, ...props})
{
    //--------------------
    return (
        <div className={pageClass + (showHeader ? ' page-has-header ' : '') + (showFooter ? ' page-has-footer ' : '')}>
            { showHeader ? <Header pageTitle={pageTitle} showBtnBack={showBtnBack} /> : null }
            {props.children}
            { showFooter ? <Footer /> : null }
        </div>
    );
}

export default Template;