import React from 'react'
import {useNavigate} from "react-router-dom";
import KeyboardBackspaceTwoToneIcon from '@mui/icons-material/KeyboardBackspaceTwoTone';
import './index.css';

//**********************************************************

function Header({pageTitle = "", showBtnBack = true, ...props})
{
    const navigate = useNavigate();

    //
    const handleBack = () =>
    {
        navigate(-1);
    }

    const handleLogout = () =>
    {

    }

    //--------------------
    return (
        <header>
            <span className="header-title">{pageTitle}</span>

            {showBtnBack ? <button type="button" className="btn-back" onClick={handleBack}><KeyboardBackspaceTwoToneIcon /></button> : null}
        </header>
    );
}

export default Header;
