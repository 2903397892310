class helpers
{
    // date - time
    initDateTime(datetime: string)
    {
        var mydate = new Date(datetime);

        return this.persianNumberToEnglishNumber(mydate.toLocaleDateString('fa-IR') + ' ' + mydate.toLocaleTimeString('fa-IR'));
    }

    persianNumberToEnglishNumber(str: string)
    {
        let persianNumbers = [/۰/g, /۱/g, /۲/g, /۳/g, /۴/g, /۵/g, /۶/g, /۷/g, /۸/g, /۹/g];
        let arabicNumbers  = [/٠/g, /١/g, /٢/g, /٣/g, /٤/g, /٥/g, /٦/g, /٧/g, /٨/g, /٩/g];

        for (let i = 0; i < 10; i++)
        {
            str = str.replace(persianNumbers[i], i.toString()).replace(arabicNumbers[i], i.toString());
        }

        return str;
    }

    // init mobile
    initMobile(mobile: string)
    {
        if(mobile.indexOf("0") === 0) mobile = mobile.substring(1);
        if(mobile.indexOf("98") === 0) mobile = mobile.substring(2);
        if(mobile.indexOf("+98") === 0) mobile = mobile.substring(3);

        return mobile !== "" ? "98" + mobile : "";
    }

    // check internet
    inOnline()
    {
        return typeof navigator !== 'undefined' ? navigator.onLine : false;
    }

    // check internet
    inNumber(num: number)
    {
        return num >= 10 ? num : "0" + num;
    }

    // send sms
    sendSms(mobileNumber: string, message: string)
    {
        // window.open("sms:" + mobileNumber + "?&body=" + encodeURIComponent(message));

        var a = document.createElement('a');
        var linkText = document.createTextNode("my title text");
        a.appendChild(linkText);
        a.title = "my title text";
        a.href = "sms:" + mobileNumber + "?&body=" + encodeURIComponent(message);
        document.body.appendChild(a);
        a.click();
        a.remove();
    }

    // init speed
    initSpeed(speed: number)
    {
        return Math.round(speed * 1.852);
    }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new helpers();
