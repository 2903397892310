import React, {useEffect, useState} from "react";
import {BottomNavigation, BottomNavigationAction} from "@mui/material";
import {Link} from "react-router-dom";
import HomeTwoToneIcon from '@mui/icons-material/HomeTwoTone';
import InfoTwoToneIcon from '@mui/icons-material/InfoTwoTone';
import SettingsTwoToneIcon from '@mui/icons-material/SettingsTwoTone';
import SmsTwoToneIcon from '@mui/icons-material/SmsTwoTone';
import PlaceTwoToneIcon from '@mui/icons-material/PlaceTwoTone';
import "./index.scss";

//**********************************************************

function Footer()
{
    const [activeLabel, setActiveLabel] = useState("");
    const [isOffline, setIsOffline] = useState(false);

    //
    useEffect
    (
        () =>
        {
            let currentPage = window.location.pathname.substr(1);
            currentPage = (currentPage !== "") ? currentPage : "home";
            setActiveLabel(currentPage);
            setIsOffline(sessionStorage.getItem(String(process.env.REACT_APP_Offline)) === "1");
        },
        []
    );

    //
    const tabChange = (value: string) =>
    {
        setActiveLabel(value);
    };

    //--------------------
    return (
        <BottomNavigation value={activeLabel} onChange={(event, value) => { tabChange(value) }} showLabels className="footer-bar">
            <BottomNavigationAction value="home" data-value="home" label="خانه" icon={<HomeTwoToneIcon />} component={Link} to="/home" className="link" />
            <BottomNavigationAction value="sms" data-value="sms" label="دستورات" icon={<SmsTwoToneIcon />} component={Link} to="/sms" className="link" />
            {!isOffline ? <BottomNavigationAction value="tracking" data-value="tracking" label="ردیابی" icon={<PlaceTwoToneIcon/>} component={Link} to="/tracking" className="link"/> : null}
            <BottomNavigationAction value="setting" data-value="setting" label="تنظیمات" icon={<SettingsTwoToneIcon />} component={Link} to="/setting" className="link" />
            <BottomNavigationAction value="about" data-value="about" label="درباره ما" icon={<InfoTwoToneIcon />} component={Link} to="/about" className="link" />
        </BottomNavigation>
    );
}

export default Footer;
