import React, {useEffect, useState} from 'react';
import {Button, Card, CardActionArea, CardContent, CardMedia, Typography} from "@mui/material";
import DeleteIcon from '@mui/icons-material/HelpOutline';
import Template from "../../layouts/Template";
import imageAdd2Home from "../../assets/images/help-icon-add-to-home.png";
import imageLogo from "../../assets/images/cartracker-logo-1.png";
import imageInstallIos01 from "../../assets/images/install-ios-01.jpg";
import imageInstallIos02 from "../../assets/images/install-ios-02.jpg";
import imageInstallIos03 from "../../assets/images/install-ios-03.jpg";
import InstallMobileIcon from '@mui/icons-material/InstallMobile';
import './index.scss';

//**********************************************************

let beforeInstallPromptEvent: any;

//**********************************************************

function Index()
{
    const [showGuide, setShowGuide] = useState(false);

    const handleShowGuide = () =>
    {
        setShowGuide(!showGuide);
    };

    //
    useEffect
    (
        () =>
        {
            window.addEventListener
            (
                "beforeinstallprompt", (e) =>
                {
                    e.preventDefault();
                    beforeInstallPromptEvent = e;
                }
            );
        },
        []
    );

    const handleInstall = () =>
    {
        beforeInstallPromptEvent.prompt();
    };

    //--------------------
    return (
        <Template pageClass="page-install" showHeader={false} showFooter={false}>
            {
                showGuide ?
                    <div>
                        <div className="page-title">نصب نرم افزار</div>

                        <Card className="card-box">
                            <CardActionArea>
                                <CardMedia component="img" image={imageInstallIos01}/>
                                <CardContent>
                                    <Typography className="des">1. بر روی آیکون مشخص شده در تصویر بفشارید.</Typography>
                                </CardContent>
                            </CardActionArea>
                        </Card>

                        <Card className="card-box">
                            <CardActionArea>
                                <CardMedia component="img" image={imageInstallIos02}/>
                                <CardContent>
                                    <Typography className="des">2. در کادر باز شده بر روی آیکون + که در تصویر مشخص شده،
                                        بفشارید.</Typography>
                                </CardContent>
                            </CardActionArea>
                        </Card>

                        <Card className="card-box">
                            <CardActionArea>
                                <CardMedia component="img" image={imageInstallIos03}/>
                                <CardContent>
                                    <Typography className="des">3. در مرحله آخر بر روی Add بفشارید تا نرم افزار بر روی
                                        دستگاه شما نصب شود.</Typography>
                                </CardContent>
                            </CardActionArea>
                        </Card>
                    </div>
                    :
                    <>
                        <img className="logo" src={imageLogo} alt=""/>

                        <div className="btn-des">
                            <div className="install-requirement-des">برای استفاده از نرم افزار ابتدا بر روی دستگاه خود نصب نمایید.</div>
                            <Button fullWidth={true} className="btn" variant="contained" color="success" startIcon={<InstallMobileIcon/>} onClick={handleInstall}>نصب نرم افزار</Button>
                            <br/>
                            <Button className="btn" variant="outlined" startIcon={<DeleteIcon/>} onClick={handleShowGuide}>راهنمای نصب ios</Button>
                        </div>

                        <img src={imageAdd2Home} className="image-add-2-home" alt=""/>
                    </>
            }
        </Template>
    );
}

export default Index;
