import React from 'react';
import Template from "../../layouts/Template";
import "./index.scss";
import {Link} from "react-router-dom";
import {Button, Stack} from "@mui/material";
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import {useAppSelector} from "../../states/hooks";

//**********************************************************

function Index()
{
    const currentDeviceState = useAppSelector(state => state.deviceState);

    const Item = styled(Paper)(({ theme }) => ({
        backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
        ...theme.typography.body2,
        padding: theme.spacing(1),
        textAlign: 'center',
        color: theme.palette.text.secondary,
        flexGrow: 1,
    }));
    //--------------------
    return (
        <Template pageClass="page-setting" pageTitle="تنظیمات">
            <div className="btn-box">
                <Button to="/devices" component={Link} fullWidth={true} size="medium" variant="contained">دستگاه ها</Button>
                <Button to="/setting/device-admin" component={Link} fullWidth={true} size="medium" variant="contained">ادمین دستگاه</Button>
                {
                    currentDeviceState.deviceType === "Concox" ?
                        <Button to="/setting/central-number" component={Link} fullWidth={true} size="medium" variant="contained">شماره مرکزی</Button>
                        :
                        null
                }
                <Button to="/setting/reset-password" component={Link} fullWidth={true} size="medium" variant="contained">لغو ذخیره کلمه عبور</Button>
                <Button to="/setting/reset-device" component={Link} fullWidth={true} size="medium" variant="contained">راه اندازی مجدد دستگاه</Button>
                <Button to="/setting/device-number" component={Link} fullWidth={true} size="medium" variant="contained">تغییر شماره سیم کارت دستگاه</Button>
                {
                    currentDeviceState.deviceType === "Coban" ?
                        <Button to="/setting/point-distance" component={Link} fullWidth={true} size="medium" variant="contained">تنظیم فاصله ارسال نقطه</Button>
                        :
                        null
                }
            </div>
        </Template>
    );
}

export default Index;