import axios from "axios";
import qs from "qs";
import {tableNames} from "../database/interfaceDb";
import IndexedDb from "../database/indexedDb";

export default
{
    get: async function(path: string, params:any = null, auth:any = null)
    {
        return await this.rest('get', path, params, auth);
    },

    post: async function(path: string, params:any = null, auth:any = null)
    {
        return await this.rest('post', path, params, auth);
    },

    put: async function(path: string, params:any = null, auth:any = null)
    {
        return await this.rest('put', path, params, auth);
    },

    delete: async function(path: string, auth:any = null)
    {
        return await this.rest('delete', path, auth);
    },

    rest: async function(type: string, path: string, params:any = null, auth:any = null, apiUrl = "")
    {
        let res = null;
        let config = auth != null ? {auth: {username: auth.email, password: auth.password}, headers: {"content-type": "application/x-www-form-urlencoded"}} : {headers: {"content-type": "application/x-www-form-urlencoded"}};
        let strParams = params != null ? qs.stringify(params) : '';
        //let apiUrl = process.env.REACT_APP_ENVIRONMENT !== 'development' ? process.env.REACT_APP_API_URL : process.env.REACT_APP_API_URL_LOCAL;

        if(apiUrl === "")
        {
            const indexedDb = new IndexedDb();
            await indexedDb.open();

            const servers = await indexedDb.getAllValue(tableNames.Servers);
            apiUrl = (servers.length > 0) ? servers[0].url : "";
        }

        try
        {
            let response:any;

            switch (type)
            {
                case 'get': response = await axios.get(apiUrl + path + '?' + strParams, config); break;
                case 'post': response = await axios.post(apiUrl + path, strParams, config); break;
                case 'put': response = await axios.post(apiUrl + path + '?_method=PUT', strParams, config); break;
                case 'delete': response = await axios.delete(apiUrl + path, config); break;
            }

            res = response.data;
        }
        catch(error:any)
        {
            res = error;
            // if(error.response.status === 401)
            // {
            //     // localStorage.setItem(General.localStorageName, JSON.stringify(null));
            //     // window.location.href = '/login';
            // }
            //
            // res = error.response.data;
        }

        return res;
    },
}
